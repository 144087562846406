import React, {useEffect, useState} from 'react'
import { useParams, useNavigate} from "react-router-dom"

import Footer from '../../Components/Footer'
import {orderList} from '../../Api/Transaction'
import {priceSplitter, checkUserToken} from '../../Tools/Tools'
import CountDown from '../../Tools/CountDown'

function MyOrder(){
  const navigate = useNavigate()
  const {page} = useParams()
  const [imageUrl, setImageUrl] = useState([])
  
  const [loaderDisplay, setLoaderDisplay] = useState("flex")
  const [orderPending, setOrderPending] = useState([])
  const [orderCompleted, setOrderCompleted] = useState([])

  const [viewOrderPending, setViewOrderPending] = useState()
  const [viewOrderCompleted, setViewOrderCompleted] = useState()

  const reqOrderList = async () => {
    orderList().then((res) => {
      if(res.status == 200){
        setOrderPending(res.data['pending'])
        setOrderCompleted(res.data['completed'])
        setLoaderDisplay('none')
      }
    })
  }

  useEffect(() => {
    if(orderPending.length === 0){
      setViewOrderPending(
        <div className="w-100 text-center color-text">
          <span>Kamu belum memiliki pesanan pembelian tiket.</span>
        </div>
      )
    }else{
      setViewOrderPending(
        orderPending.map((order, Key) => {
          // var coverUrl = orderPending[Key].product_name === '1' ? process.env.REACT_APP_ASSETS_PRODUCT_WISATA : (orderPending[Key].product_name === '2' ? process.env.REACT_APP_ASSETS_PRODUCT_EVENT : process.env.REACT_APP_ASSETS_PRODUCT_SPORT)
          var coverUrl = process.env.REACT_APP_ASSETS_PRODUCT_WISATA
          var timeFormat = new Date(orderPending[Key].expiration_date)
          const timeIsoString = timeFormat.toISOString()
          return (
            <div className="item shadow-sm" onClick={() => toDetailOrder(orderPending[Key].uuid)}>
              <div className="emhead_w">
                <div className="details_w" style={{width : '80%'}}>
                  <span className="d-block size-14 font-weight-bolder">#{orderPending[Key].transaction_number}</span>
                  <span className="d-block size-14 font-weight-bolder">{orderPending[Key].business_brand_name+" ("+orderPending[Key].ticket_name+")"}</span>
                  {/* <span className="d-block size-12 color-comment">{orderPending[Key].location_name+', '+orderPending[Key].address+', '+orderPending[Key].kota+', '+orderPending[Key].province}</span> */}
                  <span className="d-block size-12 color-comment">{orderPending[Key].city+', '+orderPending[Key].province}</span>
                </div>
                <div className="icon_img">
                  <img
                    src={coverUrl+orderPending[Key].id_client+'/'+orderPending[Key].gallery_photo}
                    alt=""
                  />
                </div>
              </div>
              <div className="embody_w mb-1">
                <div className="size-12 color-comment w-100 d-flex justify-content-between">
                  <span className="">Tiket Untuk</span>
                  <span className="font-weight-normal">{orderPending[Key].selection_date} - {orderPending[Key].qty} pax</span>
                </div>
              </div>
              <div>
                <div className="size-12 color-comment w-100 d-flex justify-content-between">
                  <div className="">Batas Akhir Pembayaran</div>
                  <CountDown time={timeIsoString}/>
                </div>
              </div>
              <div className="dividar pt-2"></div>
              <div className="embody_w">
                <div className="price w-50">
                  <span>Total Pesanan</span>
                </div>
                <div className="price w-50 text-right">
                  <span>Rp </span> <span className="font-weight-bolder">{priceSplitter(orderPending[Key].amount)}</span>
                </div>
              </div>
            </div>
          )
        })
      )
    }

    if(orderCompleted.length === 0){
      setViewOrderCompleted(
        <div className="w-100 text-center color-text">
          <span>Kamu belum memiliki pesanan yang telah selesai dibayarkan.</span>
        </div>
      )
    }else{
      setViewOrderCompleted(
        orderCompleted.map((order, Key) => {
          // var coverUrl = orderCompleted[Key].product_name === '1' ? process.env.REACT_APP_ASSETS_PRODUCT_WISATA : (orderCompleted[Key].product_name === '2' ? process.env.REACT_APP_ASSETS_PRODUCT_EVENT : process.env.REACT_APP_ASSETS_PRODUCT_SPORT)
          var coverUrl = process.env.REACT_APP_ASSETS_PRODUCT_WISATA
          return (
            <div className="item shadow-sm">
              <div onClick={() => toDetailOrder(orderCompleted[Key].uuid)} >
                <div className="emhead_w">
                  <div className="details_w" style={{width : '80%'}}>
                    <span className="d-block size-14 font-weight-bolder">#{orderCompleted[Key].transaction_number}</span>
                    <span className="d-block size-14 font-weight-bolder">{orderCompleted[Key].business_brand_name+" ("+orderCompleted[Key].ticket_name+")"}</span>
                    {/* <span className="d-block size-12 color-comment">{orderCompleted[Key].location_name+', '+orderCompleted[Key].address+', '+orderCompleted[Key].kota+', '+orderCompleted[Key].province}</span> */}
                    <span className="d-block size-12 color-comment">{orderCompleted[Key].city+', '+orderCompleted[Key].province}</span>
                  </div>
                  <div className="icon_img">
                    <img
                      src={coverUrl+orderCompleted[Key].id_client+'/'+orderCompleted[Key].gallery_photo}
                      // src={coverUrl+orderCompleted[Key].id_event+'/'+orderCompleted[Key].gallery_photo}
                      alt=""
                    />
                  </div>
                </div>
                <div className="embody_w mb-1">
                  <div className="size-12 color-comment w-100 d-flex justify-content-between">
                    <span className="">Tiket Untuk</span>
                    <span className="font-weight-normal">{orderCompleted[Key].selection_date} - {orderCompleted[Key].qty} pax</span>
                  </div>
                </div>
              </div>
              <div className="dividar pt-2"></div>
              <div className="embody_w">
                <div className="w-100">
                  <button onClick={() => toEticket(orderCompleted[Key].uuid)} className="btn btn-sm bg-primary w-100 color-white text-center justify-content-center mr-2">
                    E-Tiket
                  </button>
                </div>
              </div>
            </div>
          )
        })
      )
    }
  }, [orderPending, orderCompleted])
  
  const toDetailOrder=(id)=>{
    navigate('/detail-order-wisata', {state:{orderId:id, backPage:'order'}});
  }
  
  const toEticket=(id)=>{
    navigate('/eticket', {state:{orderId:id}});
  }
  
  const toHistoryOrder=()=>{
    navigate('/history', {state:{backPage:'order'}});
  }

  useEffect(() => {
    console.log(page)
    if(!checkUserToken()){
      navigate('/login')
    }else{
      reqOrderList()
    }
  },[])

  return (
    <div id="wrapper" className="vh-100">
      <section className="em_loading" id="loader" style={{display: loaderDisplay}}>
        <div className="spinner-grow bg-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </section>
      <div id="content" className="overflow-auto">
        <header className="header_tab">
          <div className="main_haeder multi_item">
            <div className="em_side_right">
              <a
                className="rounded-circle d-flex align-items-center text-decoration-none"
                href="/home"
              >
                <i className="tio-chevron_left size-24 color-text" />
                <span className="color-text size-14">Home</span>
              </a>
            </div>
            <div className="title_page">
              <span className="page_name">Daftar Pesanan</span>
            </div>
            <div className="em_side_right" onClick={() => toHistoryOrder()}>
              <div className="btn rounded-circle share-button bg-transparent">
                <svg id="Layer_1" className="color-comment" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 137.78 142.88"><title>order-history</title><path d="M70.71,116.29H7.46a7.48,7.48,0,0,1-5.27-2.19L2,113.87a7.43,7.43,0,0,1-2-5V7.46A7.45,7.45,0,0,1,2.19,2.19L2.42,2a7.42,7.42,0,0,1,5-2H91.88a7.48,7.48,0,0,1,7.46,7.46V66.63a3.21,3.21,0,0,1-.06.63,28.75,28.75,0,1,1-28.57,49ZM85.18,82.12h2.89a2,2,0,0,1,1.43.59,2.06,2.06,0,0,1,.6,1.44V94.77h9.59a2,2,0,0,1,2,2v3a2.12,2.12,0,0,1-.6,1.44l-.08.07a2,2,0,0,1-1.35.52H84a1,1,0,0,1-1-1V84a2,2,0,0,1,.59-1.29,2,2,0,0,1,1.43-.6Zm7.75-16.47V7.46a1.1,1.1,0,0,0-1.05-1H7.46a1.08,1.08,0,0,0-.66.23l-.08.08a1.06,1.06,0,0,0-.31.74V108.84a1,1,0,0,0,.23.65l.09.08a1,1,0,0,0,.73.32H65A28.75,28.75,0,0,1,89,65.38a28,28,0,0,1,3.9.27Zm12.36,12.22A23,23,0,1,0,112,94.13a22.92,22.92,0,0,0-6.73-16.26Zm-84.5-3.78h9A1.18,1.18,0,0,1,31,75.27v9a1.18,1.18,0,0,1-1.18,1.18h-9a1.18,1.18,0,0,1-1.18-1.18v-9a1.18,1.18,0,0,1,1.18-1.18Zm22,9.28a3.65,3.65,0,0,1,0-7.18h9.58a3.65,3.65,0,0,1,0,7.18Zm-22-61.22h9A1.18,1.18,0,0,1,31,23.33v9a1.18,1.18,0,0,1-1.18,1.18h-9a1.18,1.18,0,0,1-1.18-1.18v-9a1.18,1.18,0,0,1,1.18-1.18Zm22,9.27a3.33,3.33,0,0,1-3-3.58,3.34,3.34,0,0,1,3-3.59H78.25a3.34,3.34,0,0,1,3,3.59,3.33,3.33,0,0,1-3,3.58ZM18.34,54.1a2,2,0,0,1,.38-2.82,2.23,2.23,0,0,1,3-.09l2.1,2.17L29.07,48a1.93,1.93,0,0,1,2.82.3,2.23,2.23,0,0,1,.18,3l-7,7.14a1.94,1.94,0,0,1-2.82-.3l-.16-.19a1.94,1.94,0,0,1-.31-.26L18.34,54.1Zm24.4,2.69a3.34,3.34,0,0,1-3-3.59,3.34,3.34,0,0,1,3-3.59H78.25a3.34,3.34,0,0,1,3,3.59,3.34,3.34,0,0,1-3,3.59Z" /></svg>
              </div>
            </div>
          </div>
          <div className="tab__line two_item">
            <ul className="nav nav-pills" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <a
                  className={page !== 'completed' ? "nav-link active" : "nav-link"}
                  id="pills-Waiting-tab"
                  data-toggle="pill"
                  href="#pills-Waiting"
                  role="tab"
                  aria-controls="pills-Waiting"
                  aria-selected={page !== 'completed' ? 'true' : 'false'}
                >
                  Menunggu Pembayaran
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className={page === 'completed' ? "nav-link active" : "nav-link"}
                  id="pills-Paid-tab"
                  data-toggle="pill"
                  href="#pills-Paid"
                  role="tab"
                  aria-controls="pills-Paid"
                  aria-selected={page === 'completed' ? 'true' : 'false'}
                >
                  Pesanan Selesai
                </a>
              </li>
            </ul>
          </div>
        </header>
        {/* End.main_haeder */}
        <section className="components_page paddingTab_header emPage__billsWallet">
          <div className="tab-content padding-20" id="pills-tabContent">
            <div
              className={page !== 'completed' ? "tab-pane fade show active" : "tab-pane fade"}
              id="pills-Waiting"
              role="tabpanel"
              aria-labelledby="pills-Waiting-tab"
            >
              <div className="emBk__bills">
                {viewOrderPending}
              </div>
            </div>
            <div
              className={page === 'completed' ? "tab-pane fade show active" : "tab-pane fade"}
              id="pills-Paid"
              role="tabpanel"
              aria-labelledby="pills-Paid-tab"
            >
              <div className="emBk__bills">
                {viewOrderCompleted}
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer/>
    </div>

  )
}
export default MyOrder;