import React, {useEffect, useState, useRef} from 'react'
import { useNavigate, useLocation } from "react-router-dom"
import QRCode from 'react-qr-code';

import {getOrderDetail, reqSendEticket} from '../../Api/Transaction'
import {checkUserToken} from '../../Tools/Tools'

function Eticket(){
  const navigate = useNavigate()
  const location = useLocation()
  const orderId = location.state.orderId
  const imageUrl = process.env.REACT_APP_ASSETS_PRODUCT_WISATA
  
  const [loaderDisplay, setLoaderDisplay] = useState("flex")
  // const [orderDetail, setOrderDetail] = useState([])
  const [data, setData] = useState([])
  const [detailQty, setDetailQty] = useState([])
  const [detailPrice, setDetailPrice] = useState([])
  const btnSendMail = useRef(null)
  const btnSendMailLoad = useRef(null)

  const reqOrderDetail = async () => {
    getOrderDetail(orderId).then((res) => {
      setData(res.data['order'])
      setDetailQty(
        res.data['orderDetail'].map((qty, Key) => {
          return(
            <li className="mb-1">
              <span>Qty tiket ({qty.customer_type})</span>
              <span className="font-weight-normal">{qty.qty_ticket} Tiket</span>
            </li>
          )
        })
      )
      setLoaderDisplay('none')
    })
  }
  
  const sendToMailHandler = () => {
    btnSendMail.current.className = 'd-none'
    btnSendMailLoad.current.className = 'd-block'
    reqSendEticket(orderId).then((res) => {
      console.log(res)
      btnSendMail.current.className = 'd-block'
      btnSendMailLoad.current.className = 'd-none'
      if(res.status === 200){
        console.log(res)
      }else{
        console.log(res)
      }
      
      window.$('#mdl_sendMail').modal({backdrop: false});
      window.$('#mdl_sendMail').modal('show');
      setTimeout(function(){ 
        window.$('#mdl_sendMail').modal('hide');
      }, 5000)
    })
  }
  
  const toBackpage = () => {
    // navigate('/order')
    navigate('/detail-order-wisata', {state:{orderId:orderId, backPage:'order'}});
  }

  useEffect(() => {
    // console.log(data.uuid)
    if(!checkUserToken()){
      navigate('/login')
    }else{
      reqOrderDetail()
    }
  },[])

  return (
    <div id="wrapper" className="vh-100">
      <section className="em_loading" id="loader" style={{display: loaderDisplay}}>
        <div className="spinner-grow bg-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </section>
      <div id="content" className="overflow-auto">
        {/* Start main_haeder */}
        <header className="header_tab shadow-sm">
          <div className="main_haeder multi_item">
            <div className="em_side_right">
              <span
                className="rounded-circle d-flex align-items-center cursor-pointer"
                onClick={() => toBackpage()}
              >
                <i className="tio-chevron_left size-24 color-text" />
                <span className="color-text size-14">Order Detail</span>
              </span>
            </div>
            <div className="title_page">
              <span className="page_name">E-TICKET</span>
            </div>
          </div>
        </header>
        {/* End.main_haeder */}
        <section className="emPage__invoiceDetails margin-b-40">
          <div className="emhead__invoice border rounded-5 p-2 mt-3 shadow-sm">
            <div className="brand__id">
              <div className=" w-100 px-2">
                <h2 className="username size-16">{data.ticket_name}</h2>
                <img
                className="rounded-5"
                  src={imageUrl+data.id_client+'/'+data.gallery_photo}
                  style={{width: '100%'}}
                  alt=""
                />
              </div>
            </div>
            <div className="w-100 pt-2 d-flex">
              <svg className="w-20 h-20 mt-1 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                <path fill-rule="evenodd" d="M12 2a8 8 0 0 1 6.6 12.6l-.1.1-.6.7-5.1 6.2a1 1 0 0 1-1.6 0L6 15.3l-.3-.4-.2-.2v-.2A8 8 0 0 1 11.8 2Zm3 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" clip-rule="evenodd"/>
              </svg>
              {data.city+', '+data.province}
            </div>
            <div className="w-100 pt-2 d-flex">
                <svg className="w-16 h-16 mt-1 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 10h16m-8-3V4M7 7V4m10 3V4M5 20h14c.6 0 1-.4 1-1V7c0-.6-.4-1-1-1H5a1 1 0 0 0-1 1v12c0 .6.4 1 1 1Zm3-7h0v0h0v0Zm4 0h0v0h0v0Zm4 0h0v0h0v0Zm-8 4h0v0h0v0Zm4 0h0v0h0v0Zm4 0h0v0h0v0Z"/>
                </svg>
              <span className="size-14 px-2">{data.selection_date}</span>
            </div>
          </div>
          <div className="embody__invoice mt-1">
            <div className="about__sent">
              <div className="billed__to size-14 w-100">
                <h2>Informasi Pesanan</h2>
                <div className="border rounded-5 p-2 mt-1 shadow-sm">
                  <div className="card-body pt-0 pb-2 px-0">
                    <div className="w-100 pt-1 d-flex justify-content-between">
                      <span className="color-comment">No. Transaksi</span>
                      <span className="">#{data.transaction_number}</span>
                    </div>
                    <div className="w-100 pt-1 d-flex justify-content-between">
                      <span className="color-comment">Nama Lengkap</span>
                      <span className="">{data.customer_name}</span>
                    </div>
                    <div className="w-100 pt-1 d-flex justify-content-between">
                      <span className="color-comment">Tanggal Pembelian</span>
                      <span className="">{data.order_date}</span>
                    </div>
                    <div className="w-100 pt-1 d-flex justify-content-between">
                      <span className="color-comment">Jumlah Tiket</span>
                      <span className="">{data.ttl_qty_ticket} Pax</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="about__sent">
              <div className="billed__to size-14 w-100">
                <h2>QR Code</h2>
                <div className="border rounded-5 p-2 mt-1 shadow-sm">
                  <div className="card-body pt-0 pb-0 px-0">
                    <div className="w-100 pt-1 d-flex justify-content-center">
                      <div className="padding-10 bg-white w-75 rounded-10">
                        <QRCode
                          title='E-TICKET'
                          // value={data.uuid !== null ? data.uuid : 'STRING_DEFAULT'}orderId
                          value={orderId}
                          size='100%'
                        />
                      </div>
                    </div>
                    <div className="w-100 pt-1 d-flex justify-content-center">
                      <span className="size-12 color-blue font-weight-bolder">Scan QR Code diatas untuk masuk ke lokasi</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="about__sent">
              <div className="billed__to size-14 w-100">
                <h2>Syarat & Ketentuan</h2>
                <div className="border rounded-5 p-2 mt-1 shadow-sm">
                  <div className="card-body pt-0 pb-0 px-0">
                    <p className="mb-0 size-15 color-text text-wrap">
                      <div dangerouslySetInnerHTML={{__html: data.syarat_penukaran_tiket}} />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        <div className="buttons__footer" style={{bottom : 0}}>
          <div className="env-pb text-center">
            <div ref={btnSendMail} className="d-block">
              <button onClick={() => sendToMailHandler()} type="button" className="btn bg-primary w-100 color-white text-center justify-content-center px-5 size-14">
                <b>Kirim E-Tiket ke Email</b>
              </button>
            </div>
            <div ref={btnSendMailLoad} className="d-none">
              <button className="btn bg-primary w-100 color-white text-center justify-content-center px-5 size-14" type="button" disabled>
                <span className="spinner-border spinner-border-sm color-white" role="status" aria-hidden="true" />
                 Loading...
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal transition-bottom -inside screenFull defaultModal mdlladd__rate fade" id="mdl_sendMail" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="false">
        <div className="modal-dialog" style={{bottom: '55px'}}>
          <div className="modal-content rounded-5">
            <div className="modal-body rounded-5 py-2" style={{backgroundColor: "rgb(52 58 64 / 7%)"}}>
              <div className="d-flex justify-content-center color-text size-12">
                <span>E-tiket berhasil dikirim ke <span className="color-blue font-weight-bolder">yudhaniagara27@gmail.com</span>.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Eticket