import React, { useState, useEffect } from 'react'

function CountDown({time}) {
  const [viewTimeLeft, setViewTimeLeft] = useState()

  useEffect(() => {
    // console.log(time)
    const interval = setInterval(() => {
      const difference = +new Date(time) - +new Date()
      let timeLeft = {}
      
      if(difference > 0) {
        timeLeft = {
          hours: Math.floor(difference / (1000 * 60 * 60)),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        }

        setViewTimeLeft(
                          <span className="d-flex justify-content-center align-items-center color-white font-weight-bolder ml-1 rounded-10 bg-maroon padding-t-8 padding-b-8 size-12" style={{width : '100px'}}>
                            <svg className="w-16 h-16 mr-1 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24">
                              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>
                            <span>{String(timeLeft.hours ? timeLeft.hours : 0).padStart(2, '0')}</span>
                            <span>:</span>
                            <span>{String(timeLeft.minutes ? timeLeft.minutes : 0).padStart(2, '0')}</span>
                            <span>:</span>
                            <span>{String(timeLeft.seconds ? timeLeft.seconds : 0).padStart(2, '0')}</span>
                          </span>
                        )
      }else{
        setViewTimeLeft(
                          <span className="d-flex justify-content-center align-items-center color-white font-weight-bolder ml-1 rounded-10 bg-grey padding-t-8 padding-b-8 size-12" style={{width : '100px'}}>
                            <span>Expired</span>
                          </span>
                        )
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  return viewTimeLeft
}

export default CountDown