import React, {useEffect, useState, useRef} from 'react'
import { useNavigate, useLocation } from "react-router-dom"

import {getOrderDetail} from '../../Api/Transaction'
import {priceSplitter, checkUserToken} from '../../Tools/Tools'

function DetailOrderWisata(){
  const navigate = useNavigate()
  const location = useLocation()
  const [imageUrl, setImageUrl] = useState([])

  const orderId = location.state.orderId
  const backPage = location.state.backPage
  
  const [loaderDisplay, setLoaderDisplay] = useState("flex")
  const [data, setData] = useState([])
  const [detailQty, setDetailQty] = useState([])
  const [detailPrice, setDetailPrice] = useState([])

  const itemDivVisitor = useRef(null)

  const reqOrderDetail = async () => {
    getOrderDetail(orderId).then((res) => {
      if(res.status === 200){
        setData(res.data['order'])
        setImageUrl(process.env.REACT_APP_ASSETS_PRODUCT_WISATA)
        setDetailQty(
          res.data['orderDetail'].map((qty, Key) => {
            return(
              <li className="mb-1">
                <span>Qty tiket ({qty.customer_type})</span>
                <span className="font-weight-normal">{qty.qty_ticket} Tiket</span>
              </li>
            )
          })
        )

        setDetailPrice(
          res.data['orderDetail'].map((price, Key) => {
            return(
              <li className="mb-1">
                <span>Harga tiket ({price.customer_type})</span>
                <span className="font-weight-normal">Rp {priceSplitter(price.origin_price)}</span>
              </li>
            )
          })
        )
        
        setLoaderDisplay('none')
      }
    })
  }

  const paymentHandler = () => {
    if(data.payment_method === 'VA'){
      navigate('/payment', {state: { 
                                      accountNumber : data.va_number,
                                      bankCode : data.bank,
                                      expectedAmount : data.final_amount,
                                      expirationDate : data.exp_date,
                                      event_name : data.ticket_name,
                                      ticket_name : data.customer_group.toUpperCase(),
                                      topik_name : 'Wisata'
                                    }})
    }else if(data.payment_method === 'QRIS'){
      navigate('/payment-qris', {state: {
                                          qr_id : data.third_party_id,
                                          qr_string : data.qr_code_string,
                                          expectedAmount : data.final_amount,
                                          event_name : data.ticket_name,
                                          ticket_name : data.customer_group.toUpperCase(),
                                          topik_name : 'Wisata'
                                        }})
    }else if(data.payment_method === 'E-WALLET'){
      navigate('/payment-ewallet', {state: {
                                              expectedAmount : data.final_amount,
                                              desktopWebUrl : data.ewallet_desktop_web_checkout_url,
                                              mobileWebUrl : data.ewallet_mobile_web_checkout_url,
                                              mobileDeeplinkUrl : data.ewallet_mobile_deeplink_checkout_url,
                                              channelCode : data.bank.replace('ID_', ''),
                                              event_name : data.ticket_name,
                                              ticket_name : data.customer_group.toUpperCase(),
                                              topik_name : 'Wisata'
                                            }})
    }
  }
  
  const toEticket=(id)=>{
    navigate('/eticket',{state:{orderId:id}});
  }

  useEffect(() => {
    if(!checkUserToken()){
      navigate('/login')
    }else{
      reqOrderDetail()
    }
  },[])

  return (
    <div id="wrapper" className="vh-100">
      <section className="em_loading" id="loader" style={{display: loaderDisplay}}>
        <div className="spinner-grow bg-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </section>
      <div id="content" className="overflow-auto">
        <header className="header_tab shadow-sm">
          <div className="main_haeder multi_item">
            <div className="em_side_right">
              <a
                className="rounded-circle d-flex align-items-center text-decoration-none"
                href={'/'+backPage}
              >
                <i className="tio-chevron_left size-24 color-text" />
                <span className="color-text size-14">Back</span>
              </a>
            </div>
            <div className="title_page">
              <span className="page_name">Detail Pesanan</span>
            </div>
          </div>
        </header>
        <section className="emPage__invoiceDetails margin-b-30">
          <div className="emhead__invoice">
            <div className="brand__id">
              <div className="brand">
                <img
                  src={imageUrl+data.id_client+'/'+data.gallery_photo}
                  height={'50px'}
                  width={'auto'}
                  alt=""
                />
              </div>
              <div className="date_ticket">
                <span className="id color-secondary">#{data.transaction_number}</span>
                <span className="date color-text">{data.order_date}</span>
              </div>
            </div>
            <div className="w-100 pt-2 d-flex justify-content-between align-items-center">
              <span className="color-comment">Status</span>
              {
                data.status === 'PENDING' ? (
                  <span className="color-yellow border border-yellow rounded-8 padding-3 size-11 px-2">Menunggu Pembayaran</span>
                ) : (data.status === 'COMPLETED' ? (
                    <span className="color-green border border-green rounded-8 padding-3 size-11 px-2">Telah Dibayarkan</span>
                  ) : (data.status === 'ARCHIVED' ? (
                    <span className="color-grey border border-grey rounded-8 padding-3 size-11 px-2">Event Selesai</span>
                  ) : (
                    <span className="bg-lightgrey border border-grey rounded-8 padding-3 size-11 px-2">Pembayaran Expired</span>
                  )
                )
                )
              }
            </div>
            <div className="w-100 pt-1 d-flex justify-content-between align-items-center">
              <span className="color-comment">Metode Pembayaran</span>
              <span className="">{data.payment_method == 'VA' ? 'Virtual Account - '+data.bank : data.payment_method+' - '+data.bank}</span>
            </div>
            {
              data.status === 'PENDING' ? (
                <div className="w-100 pt-1 d-flex justify-content-between align-items-center">
                  <span className="color-comment">Bayar Sebelum</span>
                  <span className="">{data.exp_date}</span>
                </div>
              ) : (data.status === 'COMPLETED' ? (
                <div className="w-100 pt-1 d-flex justify-content-between align-items-center">
                  <span className="color-comment">Tanggal Pembayaran</span>
                  <span className="">{data.transaction_time}</span>
                </div>
                ) : ('')
              )
            }
          </div>
          <div className="embody__invoice">
            <div className="about__sent w-100 d-block">
              <div className="billed__to size-14">
                <h2>Tiket {data.business_brand_name}:</h2>
                <p className="username size-14">{data.ticket_name}</p>
                <div className="card-body pt-0 pb-2 px-0">
                  <div className="mt-1 size-14">
                    <p className="d-inline-block size-14">
                      <svg className="w-16 h-16 dark:text-white mr-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                        <path fill-rule="evenodd" d="M12 2a8 8 0 0 1 6.6 12.6l-.1.1-.6.7-5.1 6.2a1 1 0 0 1-1.6 0L6 15.3l-.3-.4-.2-.2v-.2A8 8 0 0 1 11.8 2Zm3 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" clip-rule="evenodd"/>
                      </svg>
                      {data.city+', '+data.province}
                    </p>
                  </div>
                </div>
                <div className="w-100 pt-1 d-flex justify-content-between">
                  <span className="color-comment d-block">Tanggal Dipilih</span>
                  <span className="color-blue d-block border border-blue rounded-8 padding-3 size-12 px-2 pull-right">{data.selection_date}</span>
                </div>
              </div>
            </div>
            <div className="about__sent w-100 d-block border rounded-10 my-3 p-2 shadow-sm">
              <div className="billed__to size-14 w-100">
                <h2>Data Pemesan :</h2>
                <span className="d-block font-weight-bold text-capitalize">{data.customer_name}</span>
                <span className="d-block color-text">{data.customer_email}</span>
                <span className="d-block color-text">{data.customer_phone}</span>
              </div>
            </div>
            <div className="emtable__Details">
              <h2 className="size-14">Rincian Pembayaran:</h2>

              <div className="emPage__basket npPkg__basket default py-0">
                <div className="detailsTotally p-0">
                  <ul>
                    <li className="mb-1">
                      <span>Jumlah Pembelian Tiket</span>
                      <span className="font-weight-normal"> {data.ttl_qty_ticket} Pax</span>
                    </li>
                    {detailQty}
                    {detailPrice}
                    <li className="mb-1">
                      <span>Total Harga Tiket</span>
                      <span className="font-weight-normal">Rp {priceSplitter(data.ttl_ticket_price)}</span>
                    </li>
                    <div className="dividar my-2" />
                    <li className="mb-1">
                      <span>Biaya Penanganan</span>
                      <span className="font-weight-normal text-muted">Rp {priceSplitter(data.admin_fee)}</span>
                    </li>
                    <li className="mb-1">
                      <span>Asuransi</span>
                      <span className="font-weight-normal text-muted">Rp {priceSplitter(data.insurance_amount)}</span>
                    </li>
                    <li className="mb-1">
                      <span>Potongan Kode Promo</span>
                      <span className="font-weight-normal text-success">- Rp {priceSplitter(data.discount_amount)}</span>
                    </li>
                    <li className="mb-1">
                      <span>Potongan Poinmu</span>
                      <span className="font-weight-normal text-success">- Rp {priceSplitter(data.poin_used)}</span>
                    </li>
                    <div className="dividar my-3" />
                    <li className="mb-1">
                      <span className="size-16 text_total">Total Pembayaran</span>
                      <span className="number_total size-16">Rp {priceSplitter(data.final_amount)}</span>
                    </li>
                    <li className=" d-flex justify-content-end text-end">
                      <span className="mb-1 color-text size-12 mt-0">
                        <img src={window.location.origin + "/assets/star.png"} width={'auto'} height={14} alt="" className="mr-1 margin-b-9"/>
                        Poin
                        <span className="ml-1 text-primary size-12 d-inline">{data.poin_received}</span>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        <div className="buttons__footer" style={{bottom : 0}}>
          <div className="env-pb text-center">
            {
              data.status === 'PENDING' ? (
                <button type="button" className="btn bg-primary w-100 color-white text-center justify-content-center px-5 size-14" onClick={() => paymentHandler()}>
                  <b>Selesaikan pembayaran</b>
                </button>
              ) : (data.status === 'COMPLETED' ? (
                <button onClick={() => toEticket(data.uuid)} type="button" className="btn bg-primary w-100 color-white text-center justify-content-center px-5 size-14">
                  <b>E-Ticket</b>
                </button>
                ) : (
                  <a href="/home" type="button" className="btn btn-secondary w-100 color-white text-center justify-content-center px-5 size-14">
                    <b>Home</b>
                  </a>
                  )
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default DetailOrderWisata