import React, {useEffect, useRef, useState} from 'react'
import {useLocation} from "react-router-dom"
import {priceSplitter} from '../../Tools/Tools'

function Payment(){
  const location = useLocation()
  const bankCode = useRef(location.state.bankCode)
  const accountNumber = useRef(location.state.accountNumber)
  const expectedAmount = useRef(location.state.expectedAmount)
  const expirationDate = useRef(location.state.expirationDate)
  const eventName = useRef(location.state.event_name)
  const ticketName = useRef(location.state.ticket_name)
  const topikName = useRef(location.state.topik_name)
  const [loaderDisplay, setLoaderDisplay] = useState("flex")
  const bankLogo = '/assets/img/banks/'+bankCode.current.toLowerCase()+'-logo.svg'

  const copyHandler = (vaNumber) => {
    navigator.clipboard.writeText(vaNumber)
    window.$('#mdl_copy').modal({backdrop: false});
    window.$('#mdl_copy').modal('show');
    setTimeout(function(){ 
      window.$('#mdl_copy').modal('hide');
    }, 2000)
  }

  useEffect(() => {
    setTimeout(function(){ 
      setLoaderDisplay('none')
    
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      })
    }, 1000)
  },[])

  return (
    <div id="wrapper" style={{'height' : '100vh'}}>
      <section className="em_loading" id="loader" style={{display: loaderDisplay}}>
        <div className="spinner-grow bg-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </section>
      <div id="content" style={{height : '100vh'}}>
        <section className="background_header bg-primary" style={{'height' : 'auto'}}>
          <div className="npPage_SuccessPkg p-5">
            <div className="txt">
              <span className="d-block size-16 color-white font-weight-bolder">Selesaikan Segera Pembayaranmu Melalui</span>
              <div className="logo-payment bg-white rounded-10 py-2 my-3 w-50">
                <img src={bankLogo} alt="" width={100} height={'auto'}/>
              </div>
              <span className="d-block size-16 color-white font-weight-bolder">Virtual Account</span>
              <p>
                Batas akhir pembayaran
              </p>
              <p>
                <b>{expirationDate.current} WIB</b>
              </p>
            </div>
          </div>
        </section>
        <section className="padding-20">
          <div className="title_sm pb-3">
            <span>Detail Pembayaran</span>
          </div>
          <div className="emPage__basket npPkg__basket justBoxes">
            <div className="itemPkg_cart">
              <div className="sideleft">
                <div>
                  <p>Tiket {ticketName.current}</p>
                  <h3>{eventName.current}</h3>
                </div>
              </div>
              <div className="price">
                <span className="size-13 color-primary">{topikName.current}</span>
              </div>
            </div>
          </div>
        </section>
        <section className="padding-20 pt-0 padding-b-40">
          <div className="npPkg__paymentDetails">
            <ul>
              <li>
                <span>Metode Pembayaran</span>
                <span>Virtual Account</span>
              </li>
              <li>
                <span>Virtual Account</span>
                <span onClick={() => copyHandler(accountNumber.current)}>
                  {accountNumber.current}
                  <svg className="w-16 h-16 ml-1 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linejoin="round" stroke-width="2" d="M9 8v3a1 1 0 0 1-1 1H5m11 4h2a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1h-7a1 1 0 0 0-1 1v1m4 3v10a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1v-7.13a1 1 0 0 1 .24-.65L7.7 8.35A1 1 0 0 1 8.46 8H13a1 1 0 0 1 1 1Z"/>
                  </svg>
                </span>
              </li>
              <li>
                <span>Total Pembayaran</span>
                <span className="weight-600"><span className="color-primary">Rp {priceSplitter(expectedAmount.current)}</span></span>
              </li>
            </ul>
          </div>
        </section>
      </div>
        
      <div className="buttons__footer" style={{bottom : 0}}>
        <div className="env-pb text-center">
          <a 
            href="/home"
            className="btn bg-primary rounded-10 w-100 size-14 color-white text-center justify-content-center px-5 mb-2 font-weight-bolder" 
            // style={{backgroundColor : '#c58fff'}}
          >
            Pesan Tiket Lainnya
          </a>
        </div>
        <div className="env-pb text-center">
          <a 
            href="/order"
            className="btn btn-secondary rounded-10 w-100 size-14 color-white text-center justify-content-center px-5 mb-2 font-weight-bolder" 
            // style={{backgroundColor : '#c58fff'}}
          >
            Lihat Daftar Pesanan
          </a>
        </div>
      </div>

      <div className="modal transition-bottom -inside screenFull defaultModal mdlladd__rate fade" id="mdl_copy" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="false">
        <div className="modal-dialog">
          <div className="modal-content rounded-5">
            <div className="modal-body rounded-5 py-2" style={{backgroundColor: "rgb(52 58 64 / 7%)"}}>
              <div className="d-flex justify-content-center color-text size-12">
                <span>Copied to clipboard.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Payment