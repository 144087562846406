import React, {useEffect, useRef, useState} from 'react'
import {useLocation, useNavigate, useParams} from "react-router-dom"
import moment from 'moment'

import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'

import {productDetail} from '../../Api/Product'
import {priceSplitter, checkUserToken, isWeekday} from '../../Tools/Tools'

function ProductWisataDetail(){
  const location = useLocation()
  const navigate = useNavigate()
  const {slug} = useParams()
  const [imageUrl, setImageUrl] = useState([])

  const productCategory = useRef()
  const dateToday = useRef(moment().format('Y-MM-DD'))
  const [loaderDisplayBg0, setLoaderDisplayBg0] = useState("none")
  const [loaderDisplay, setLoaderDisplay] = useState("flex")
  const userFullname = localStorage.getItem('fullname') ===  null ? '' : localStorage.getItem('fullname')
  const selectTicket = useRef()

  const orderName = useRef(userFullname)
  const [dataOrder, setDataOrder] = useState([])
  const [productData, setProductData] = useState([])
  const [packageData, setPackageData] = useState([])
  const [dateList, setDateList] = useState([])
  const [qtyTicket, setQtyTicket] = useState(1)
  const [selectDate, setSelectDate] = useState(1)
  const [alertZero, setAlertZero] = useState('')
  const [mdlBodyPackage, setMdlBodyPackage] = useState([])

  const [imageBanner, setImageBanner] = useState([])

  const reqDetail = async () => {
    productDetail(slug, 1).then((res) => {
      if(res.responseCode === 200000){
        setLoaderDisplay('none')
        const arr = []
        res.responseData.package.map((package_detail, Key) => {
          var disc = res.responseData.package[Key].discount_amount === 0 ? (res.responseData.package[Key].discount_percentage === 0 ? 0 : res.responseData.package[Key].discount_percentage) : res.responseData.package[Key].discount_amount
          var start_date_discount = res.responseData.package[Key].discount_effective_date === '' || res.responseData.package[Key].discount_effective_date === null ? '1970-01-01' : res.responseData.package[Key].discount_effective_date
          var start_end_discount = res.responseData.package[Key].discount_expired_date === '' || res.responseData.package[Key].discount_expired_date === null ? '1970-01-01' : res.responseData.package[Key].discount_expired_date
          var discStart = new Date(start_date_discount)
          var discEnd = new Date(start_end_discount)

          var arr_order = [{
                            'idPackage' : res.responseData.package[Key]['id_package'],
                            'customerGroup' : '',
                            'customerType' : '',
                            'selectDate' : dateToday.current,
                            'minQty' : 0,
                            'idPriceRegulerGrup' : 0,
                            'idPriceChild' : 0,
                            'idPriceAdult' : 0,
                            'originPriceChild' : 0,
                            'originPriceAdult' : 0,
                            'originPriceRegulerGrup' : 0,
                            'qtyTicketRegulerGrup' : 0,
                            'qtyTicketChild' : 0,
                            'qtyTicketAdult' : 0,
                            'ttlPriceRegulerGrup' : 0,
                            'ttlPriceChild' : 0,
                            'ttlPriceAdult' : 0,
                            'poinRewardRegulerGrup' : 0,
                            'poinRewardChild' : 0,
                            'poinRewardAdult' : 0,
                            'freeTicket' : 0,
                            'alertZero' : ''
                          }]

          arr.push(arr_order)
          setDataOrder([...arr])
          // console.log(arr_order)
        })

        productCategory.current = res.responseData.ticket_detail.business_type

        if(res.responseData.ticket_detail.gallery_photo.includes(",")){
          setImageBanner(res.responseData.ticket_detail.gallery_photo.split(','))
        }

        setImageUrl(res.responseData.ticket_detail.business_type === '1' || res.responseData.ticket_detail.business_type === 1 ? process.env.REACT_APP_ASSETS_PRODUCT_WISATA : (res.responseData.ticket_detail.business_type === '2' || res.responseData.ticket_detail.business_type === 2 ? process.env.REACT_APP_ASSETS_PRODUCT_EVENT : process.env.REACT_APP_ASSETS_PRODUCT_SPORT))
        setProductData(res.responseData.ticket_detail)
        setPackageData(res.responseData.package)
      }
    })
  }

  const qtyHandler = (key, type, operator) => {
    dataOrder[key][0].alertZero = ''
    if(operator === 'up'){
      if(type === 'Dewasa'){
        dataOrder[key][0].qtyTicketAdult = parseInt(dataOrder[key][0].qtyTicketAdult)+1
      }else if(type === 'Anak'){
        dataOrder[key][0].qtyTicketChild = parseInt(dataOrder[key][0].qtyTicketChild)+1
      }else{
        dataOrder[key][0].qtyTicketRegulerGrup = parseInt(dataOrder[key][0].qtyTicketRegulerGrup)+1
      }
    }else if(operator === 'down'){
      if(type === 'Dewasa' && dataOrder[key][0].qtyTicketAdult > 0){
        dataOrder[key][0].qtyTicketAdult = dataOrder[key][0].qtyTicketAdult-1
      }else if(type === 'Anak' && dataOrder[key][0].qtyTicketChild > 0){
        dataOrder[key][0].qtyTicketChild = dataOrder[key][0].qtyTicketChild-1
      }else if(dataOrder[key][0].qtyTicketRegulerGrup > dataOrder[key][0].minQty){
        dataOrder[key][0].qtyTicketRegulerGrup = dataOrder[key][0].qtyTicketRegulerGrup-1
      }
    }

    setBodyMdlPackage(key)
  }

  const selectDateChange = (key, date) => {
    setAlertZero('')
    dataOrder[key][0].alertZero = ''
    setSelectDate(date.target.value)
    dataOrder[key][0].selectDate = date.target.value
    setBodyMdlPackage(key)
  }

  const orderHandle = (key) => {
    if(!checkUserToken()){
      window.$('#mdl_package_price').modal('hide');
      navigate('/login')
    }else{
      dataOrder[key][0].alertZero = ''
      if(dataOrder[key][0].selectDate === ''){
        setAlertZero('Pilih tanggal event terlebih dahulu.')
        dataOrder[key][0].alertZero = '*Pilih tanggal event terlebih dahulu.'
          setBodyMdlPackage(key)
      }else{
        if(dataOrder[key][0].qtyTicketAdult + dataOrder[key][0].qtyTicketChild + dataOrder[key][0].qtyTicketRegulerGrup === 0){
          setAlertZero('Tambah jumlah tiket terlebih dahulu.')
          dataOrder[key][0].alertZero = '*Tambah jumlah tiket terlebih dahulu.'
          setBodyMdlPackage(key)
        }else{
          setAlertZero('')
          dataOrder[key][0].alertZero = ''
          setLoaderDisplayBg0('flex')
          setTimeout(function() { 
            window.$('#mdl_package_price').modal('hide');
            console.log(dataOrder[key])
            navigate('/checkout-wisata', { state: {
                                                    slug : slug,
                                                    productData : productData,
                                                    dataOrder : dataOrder[key]
                                                  }})
          }, 300)
        }
      }
      setAlertZero(key)
    }
  }
  
  const showPackageMdl = (key) => {
    setBodyMdlPackage(key)
    window.$('#mdl_package_price').modal('show');
  }

  const setBodyMdlPackage = (key) => {
    var diffWeekdayWeekend = 0
    var specialOffer = 0
    var dateSpecialOffer = null

    var discType = null
    var discAmount = 0
    var discPercentage = 0
    var discStart = moment(packageData[key].discount_effective_date).format('Y-MM-DD')
    var discEnd = moment(packageData[key].discount_expired_date).format('Y-MM-DD')
    var dateDiscOffer = null
    var minQty = dataOrder[key][0].minQty
    var freeTicket = dataOrder[key][0].freeTicket

    packageData[key]['package_price'].map((packagePrice, priceKey) => {
      if(packagePrice.type == null && packageData[key].customer_group === 'grup' && dataOrder[key][0].minQty == 0){
        minQty = packagePrice.min_ticket > 1 ? packagePrice.min_ticket : 0
        freeTicket = packagePrice.free_ticket > 1 ? packagePrice.free_ticket : 0
  
        dataOrder[key][0].minQty = minQty > 1 ? minQty : 0
        dataOrder[key][0].qtyTicketRegulerGrup = minQty > 1 ? minQty : 0
        dataOrder[key][0].freeTicket = freeTicket > 1 ? freeTicket : 0
      }
      
      if(packagePrice.price_special !== 0 && specialOffer === 0 && moment(packagePrice.price_special_end_date).format('Y-MM-DD') >= dateToday.current){
        specialOffer = 1
        if(packagePrice.price_special_start_date != packagePrice.price_special_end_date){
          dateSpecialOffer = moment(packagePrice.price_special_start_date).format('ll')+' sampai '+moment(packagePrice.price_special_end_date).format('ll')
        }else{
          dateSpecialOffer = packagePrice.price_special_start_date
        }
      }

      if(packagePrice.price_weekday !== packagePrice.price_weekend){
        diffWeekdayWeekend = 1
      }
    })

    if(dateToday.current >= discStart && dateToday.current <= discEnd){
      dateDiscOffer = moment(packageData[key].discount_effective_date).format('ll')+' sampai '+moment(packageData[key].discount_expired_date).format('ll')
      if(packageData[key].discount_amount != 0){
        discType = 'Amount'
        discAmount = packageData[key].discount_amount
      }else if(packageData[key].discount_percentage != 0){
        discType = 'Percentage'
        discPercentage = packageData[key].discount_percentage
      }
    }

    setMdlBodyPackage((
      <div>
        <div className="modal-body border-top overflow-auto" style={{height : '65vh', backgroundColor : '#f7f7f8'}}>
          <div className="item rounded-8 border bg-white shadow-sm mt-2">
            <div className="emhead_w py-2 px-3">
              <div className="details_w" style={{width : '100%'}}>
                <div className="d-block size-12">
                  <div className="d-flex justify-content-between">
                    <div>
                      <p className="text-muted mb-0">Paket Pilihan</p>
                      <span className="d-block size-14 text-uppercase weight-500 mb-1">{packageData[key].package_name}</span>
                    </div>
                    <div>
                    {
                      discType !== null ? (discAmount !== 0 ? (
                        <span className="badge badge-primary bg-yellow">
                          Disc Rp. {priceSplitter(discAmount)}
                        </span>
                      ) : (
                        <span className="badge badge-primary bg-yellow">
                          Disc {discPercentage}%
                        </span>
                      )) : ''
                    }
                    </div>
                  </div>
                  {
                    diffWeekdayWeekend !== 0 ? (
                      <span className="d-block color-medium">*Ada perbedaan harga diweekday & weekend</span>
                    ) : ''
                  }
                  {
                    specialOffer !== 0 ? (
                      <span className="d-block color-medium">*Dapatkan harga spesial lebih murah pada <b>{dateSpecialOffer}</b></span>
                    ) : ''
                  }
                  {
                  discType !== null ? (
                    <span className="d-block color-medium">*Diskon berlaku pada <b>{dateDiscOffer}</b></span>
                    ) : ''
                  }
                  {
                  minQty > 1 ? (
                    <span className="d-block color-medium">*Minimun order <b>{minQty}</b> tiket</span>
                    ) : ''
                  }
                  {
                  freeTicket > 0 ? (
                    <span className="d-block color-medium">*Dapat free tiket <b>{freeTicket}</b> tiket</span>
                    ) : ''
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="w-100 mt-1 pt-2">
            <div className="w-auto">
              <span className="size-12 text-dark d-block mb-1">Pilih Tanggal </span>
              <div className="mt-2">
                <input type="date" className="form-control form-control-sm" min={dateToday.current} value={dataOrder[key][0].selectDate} onChange={(e) => selectDateChange(key, e)}/>
              </div>
            </div>
          </div>
          <section className="components_page">
            <div className="tab-content" id="pills-tabContent">
              <div className="tab-pane fade show active" id="pills-penonton" role="tabpanel" aria-labelledby="pills-home-tab">
                <div className="emPage___profile accordion accordion_inset py-0 bg-snow pb-2 pt-3" id="accordionExample-inset">
                  <div className="emBody__navLink">
                    <ul>
                      {
                        packageData[key] !== undefined ? 
                        packageData[key]['package_price'].map((packagePrice, priceKey) => {
                          if(packagePrice.type === null || packagePrice.type === ''){
                            // dataOrder[key][0].idPriceRegulerGrup = packagePrice.type === null ? packagePrice.id_price : ''
                            dataOrder[key][0].idPriceRegulerGrup = packagePrice.id_price
                          }else if(packagePrice.type === 'Dewasa'){
                            dataOrder[key][0].idPriceAdult = packagePrice.type === 'Dewasa' ? packagePrice.id_price : ''
                          }else if(packagePrice.type === 'Anak'){
                            dataOrder[key][0].idPriceChild = packagePrice.type === 'Anak' ? packagePrice.id_price : ''
                          }else{
                            dataOrder[key][0].idPriceRegulerGrup = packagePrice.id_price
                          }

                          var discType = null
                          var discAmount = 0
                          var discPercentage = 0
                          var discStart = moment(packageData[key].discount_effective_date).format('Y-MM-DD')
                          var discEnd = moment(packageData[key].discount_expired_date).format('Y-MM-DD')
                          var originPrice = isWeekday(dataOrder[key][0].selectDate) ? packagePrice.price_weekday : packagePrice.price_weekend
                          var discPrice = 0
                          var priceAfterDisc = originPrice
                          var poin = isWeekday(dataOrder[key][0].selectDate) ? packagePrice.poin_reward_weekday : packagePrice.poin_reward_weekend

                          if(packagePrice.price_special !== 0){
                            if(moment(dataOrder[key][0].selectDate) >= moment(packagePrice.price_special_start_date) && moment(dataOrder[key][0].selectDate) <= moment(packagePrice.price_special_end_date)){
                              originPrice = packagePrice.price_special
                              priceAfterDisc = originPrice
                              poin = packagePrice.poin_reward_special_day
                            }else{
                              if(moment(dataOrder[key][0].selectDate) >= moment(discStart) && moment(dataOrder[key][0].selectDate) <= moment(discEnd)){
                                if(parseInt(packageData[key].discount_amount) !== 0){
                                  discType = 'Amount'
                                  discAmount = packageData[key].discount_amount
                                  discPrice = packageData[key].discount_amount
                                }else if(parseInt(packageData[key].discount_percentage) !== 0){
                                  discType = 'Percentage'
                                  discPercentage = packageData[key].discount_percentage
                                  discPrice = discPercentage/100*originPrice
                                  poin = packagePrice.poin_reward_weekend_discount
                                }
                                
                                priceAfterDisc = originPrice - discPrice
                                poin = isWeekday(dataOrder[key][0].selectDate) ? packagePrice.poin_reward_weekday_discount : packagePrice.poin_reward_weekend_discount
                              }
                            }
                          }else{
                            if(moment(dataOrder[key][0].selectDate) >= moment(discStart) && moment(dataOrder[key][0].selectDate) <= moment(discEnd)){
                              if(packageData[key].discount_amount !== 0){
                                discType = 'Amount'
                                discAmount = packageData[key].discount_amount
                                discPrice = packageData[key].discount_amount
                              }else if(packageData[key].discount_percentage !== 0){
                                discType = 'Percentage'
                                discPercentage = packageData[key].discount_percentage
                                discPrice = discPercentage/100*originPrice
                              }
                              
                              priceAfterDisc = originPrice - discPrice
                            }
                          }

                          dataOrder[key][0].customerGroup = packageData[key].customer_group
                          dataOrder[key][0].customerType = packageData[key].customer_type
                          if(packagePrice.type === 'Dewasa'){
                            dataOrder[key][0].originPriceAdult = priceAfterDisc
                            dataOrder[key][0].ttlPriceAdult = priceAfterDisc*dataOrder[key][0].qtyTicketAdult
                            dataOrder[key][0].poinRewardAdult = poin*dataOrder[key][0].qtyTicketAdult
                          }else if(packagePrice.type === 'Anak'){
                            dataOrder[key][0].originPriceChild = priceAfterDisc
                            dataOrder[key][0].ttlPriceChild = priceAfterDisc*dataOrder[key][0].qtyTicketChild
                            dataOrder[key][0].poinRewardChild = poin*dataOrder[key][0].qtyTicketChild
                          }else{
                            dataOrder[key][0].originPriceRegulerGrup = priceAfterDisc
                            dataOrder[key][0].ttlPriceRegulerGrup = priceAfterDisc*dataOrder[key][0].qtyTicketRegulerGrup
                            dataOrder[key][0].poinRewardRegulerGrup = poin*dataOrder[key][0].qtyTicketRegulerGrup
                          }

                          return (
                            <li className="item margin-b-10" key={priceKey}>
                              <div id="headingOne-inset" className="">
                                <div className="nav-link main_item d-flex flex-column pb-2" style={{borderRadius : 0}}>
                                  <div className="w-100 d-flex ">
                                    <div className="media align-items-center">
                                      <div className="media-body my-auto">
                                        <div className="txt size-12">
                                          <h3 className="mb-0 size-12 align-middle">
                                            <span className="d-inline-block mr-1 weight-600">
                                              {packagePrice.type === null || packagePrice.type === '' ? 'Umum' : packagePrice.type }
                                            </span>
                                            {
                                              discType !== null ? (discAmount !== 0 ? (
                                                <span className="badge badge-primary bg-yellow ">
                                                  Disc Rp. {priceSplitter(discAmount)} {discType}
                                                </span>
                                              ) : (
                                                <span className="badge badge-primary bg-yellow">
                                                  Disc {discPercentage}%
                                                </span>
                                              )) : ''
                                            }
                                          </h3>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="side_right ml-auto color-primary">
                                      <svg className="w-16 h-16 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="currentColor" viewBox="0 0 24 24">
                                        <path fillRule="evenodd" d="M5.617 2.076a1 1 0 0 1 1.09.217L8 3.586l1.293-1.293a1 1 0 0 1 1.414 0L12 3.586l1.293-1.293a1 1 0 0 1 1.414 0L16 3.586l1.293-1.293A1 1 0 0 1 19 3v18a1 1 0 0 1-1.707.707L16 20.414l-1.293 1.293a1 1 0 0 1-1.414 0L12 20.414l-1.293 1.293a1 1 0 0 1-1.414 0L8 20.414l-1.293 1.293A1 1 0 0 1 5 21V3a1 1 0 0 1 .617-.924ZM9 7a1 1 0 0 0 0 2h6a1 1 0 1 0 0-2H9Zm0 4a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2H9Zm0 4a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2H9Z" clipRule="evenodd" />
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div id={'collapseOne-inset-'+key} className="collapse show" aria-labelledby="headingOne-inset" data-parent="#accordionExample-inset">
                                <div className="card-body bg-white pt-0">
                                  <div className="w-100 border-top pt-2">
                                    <div className="d-flex align-items-center">
                                      <div className="d-flex flex-column">
                                        <span className="size-12 text-dark">Harga /pax</span>
                                        <span className="size-14 color-secondary">
                                          {
                                            packagePrice.price_special !== 0 ? (
                                              moment(dataOrder[key][0].selectDate) >= moment(packagePrice.price_special_start_date) && moment(dataOrder[key][0].selectDate) <= moment(packagePrice.price_special_end_date) ? (
                                                <div>
                                                  <span className="size-12 color-text d-block" style={{textDecoration : 'line-through'}}>
                                                    <span className="color-text">Rp</span> {isWeekday(dataOrder[key][0].selectDate) ? priceSplitter(packagePrice.price_weekday) : priceSplitter(packagePrice.price_weekend)} 
                                                  </span>
                                                  <span className="weight-600">
                                                    <span className="d-inline-block mr-1 color-text">Rp </span> {priceSplitter(packagePrice.price_special)}
                                                  </span>
                                                  <span className="d-block size-12 color-medium weight-500">Harga Spesial</span>
                                                </div>
                                              ) : (
                                                <div>
                                                  {
                                                    discPrice !== 0 ? (
                                                      <span className="size-12 color-text d-block" style={{textDecoration : 'line-through'}}>
                                                        <span className="color-text">Rp</span> {priceSplitter(originPrice)} 
                                                      </span>
                                                    ) : ''
                                                  }
                                                  <span className="weight-600">
                                                    <span className="d-inline-block mr-1 color-text">Rp </span>{priceSplitter(priceAfterDisc)}
                                                  </span>
                                                  <span className="d-block size-12 color-medium">Harga {isWeekday(dataOrder[key][0].selectDate) ? 'Weekday ' : 'Weekend '} {discPrice !== 0 ? '+ Diskon ' : ''}</span>
                                                </div>
                                              )
                                            ) : (
                                              <div>
                                                {
                                                  discPrice !== 0 ? (
                                                    <span className="size-12 color-text d-block" style={{textDecoration : 'line-through'}}>
                                                      <span className="color-text">Rp</span> {priceSplitter(originPrice)} 
                                                    </span>
                                                  ) : ''
                                                }
                                                <span className="weight-600">
                                                  <span className="d-inline-block mr-1 color-text">Rp </span>{priceSplitter(priceAfterDisc)}
                                                </span>
                                                <span className="d-block size-12 color-medium">Harga {isWeekday(dataOrder[key][0].selectDate) ? 'Weekday ' : 'Weekend '} {discPrice !== 0 ? '+ Diskon ' : ''}</span>
                                              </div>
                                            )
                                          }
                                        </span>
                                      </div>
                                      <div className="d-flex flex-column justify-content-center ml-auto">
                                        <span className="size-12 text-dark d-block mb-1 text-center">Jumlah Tiket</span>
                                        <div className="group">
                                          <div className="itemCountr_manual horizontal hz-sm">
                                            <button className="btn btn_counter co_down" onClick={() => qtyHandler(key, packagePrice.type, 'down')}>
                                              <i className="tio-remove" />
                                            </button>
                                            <span className="form-control input_no color-secondary mb-1">
                                              {
                                                packagePrice.type === 'Dewasa' ? 
                                                dataOrder[key][0].qtyTicketAdult : 
                                                (packagePrice.type === 'Anak' ?
                                                  dataOrder[key][0].qtyTicketChild : 
                                                  dataOrder[key][0].qtyTicketRegulerGrup
                                                 )
                                              }
                                            </span>
                                            <button className="btn btn_counter co_up" onClick={() => qtyHandler(key, packagePrice.type, 'up')}>
                                              <i className="tio-add" />
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <span className="size-12 color-microsoft mt-2">
                                      {/* {dataOrder.length === 0 ? 1 : dataOrder[key][0].alertZero} */}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </li>
                          )
                        })
                        : ''
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          
          <div className="h-80"></div>
        </div>
        <div className="modal-footer">
          <div className=" pt-1" style={{bottom : 0, width : '100%'}}>
            <span className="d-block size-12 color-microsoft mb-1">
              {dataOrder[key][0].alertZero}
            </span>
            <div className="size-16 weight-600 color-primary mb-2 d-flex align-items-center" style={{'cursor' : 'default'}}>
              <div>
                <span className="d-block weight-400 size-12 color-secondary">Total <span className="text-muted">(*Diluar biaya lainnya)</span></span>
                <span className="color-text d-inline-block mr-1">Rp</span> 
                {priceSplitter(dataOrder[key][0].ttlPriceAdult + dataOrder[key][0].ttlPriceChild + dataOrder[key][0].ttlPriceRegulerGrup)} 
                <span className="d-block mb-1 color-text size-10 mt-0">
                  <img src={window.location.origin + "/assets/star.png"} width={'auto'} height={12} alt="" className="mr-1"/>
                  Poin
                  <span className="ml-1 text-primary size-10 d-inline">
                    {priceSplitter(dataOrder[key][0].poinRewardAdult + dataOrder[key][0].poinRewardChild + dataOrder[key][0].poinRewardRegulerGrup)} 
                  </span>
                </span>
              </div>
              <div className="ml-auto mt-1">
                <div className="size-12 text-muted d-block mb-1 mr-2 text-right">
                  Total Tiket 
                  <span className="color-primary">
                    <b> {dataOrder[key][0].qtyTicketAdult + dataOrder[key][0].qtyTicketChild + dataOrder[key][0].qtyTicketRegulerGrup} </b> 
                  </span> 
                  {
                  freeTicket > 0 ? (
                    <div>
                      <span className="">
                        +free tiket
                        <span className="color-primary"> <b> {freeTicket} </b> </span>
                      </span>
                    </div>
                    ) : ''
                  }
                </div>
              </div>
            </div>
            <div className="env-pb text-center">
              <button type="button" className="btn bg-primary w-100 color-white text-center justify-content-center px-5" onClick={() => orderHandle(key)}>
                <b>Pesan</b>
              </button>
            </div>
          </div>
        </div>
      </div>
    ))
  }

  const goToTicket = () => {
    selectTicket.current.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    const arrDate = []
    const from = new Date(Date.parse(productData.start_date))
    const to = new Date(Date.parse(productData.end_date))
        
    for (var day = from; day <= to; day.setDate(day.getDate() + 1)) {
      arrDate.push(moment(day).format('DD MMM YYYY'))
      setDateList([...arrDate])
    }
  }, [productData])

  useEffect(() => {
    reqDetail()
  },[])

  return (
    <div id="wrapper">
      <section className="em_loading" id="loader" style={{display: loaderDisplay}}>
        <div className="spinner-grow bg-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </section>
      <section className="em_loading" id="loaderBg0" style={{display: loaderDisplayBg0, backgroundColor: 'rgb(41 46 52 / 10%)'}}>
        <div className="spinner-grow bg-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </section>
      <div id="content">
        <header className="main_haeder header-sticky multi_item header-white">
          <div className="em_side_right">
            <a className="rounded-circle d-flex align-items-center text-decoration-none bg-white" href="/home">
              <i className="tio-chevron_left size-24 color-black m-1" style={{'color' : 'black'}} />
            </a>
          </div>
          <div className="title_page">
            {/* title here */}
          </div>
          <div className="em_side_right">
            <span className="rounded-circle d-flex align-items-center text-decoration-none bg-white" data-toggle="modal" data-target="#mdllButtons_share">
              <i className="ri-share-forward-box-line size-18 mx-2 my-1" style={{'color' : 'black'}}/>
            </span>
          </div>
        </header>
        <section className="embanner_SubCategories">
          <div className="em_head">
            <div className="cover_course">
              {
                imageBanner.length === 0 ? (
                  (<img src={imageUrl+productData.client+'/'+productData.gallery_photo} alt="image" />)
                ) : (
                  <OwlCarousel 
                    items={1} 
                    loop={true}
                    autoplay={true}
                    autoplayHoverPause={true}
                    autoplayTimeout={3000}
                    dots={true}
                    className="owl-carousel owl-theme" 
                    style={{minHeight : '50px'}}>
                    {
                      imageBanner.map((image, Key) => {
                        if(Key < 5){
                          return (
                            <div className="item em_item">
                              <div className="em_cover_img rounded-0">
                                <img className="rounded-0" src={imageUrl+productData.client+'/'+imageBanner[Key]} alt="" />
                              </div>
                            </div>
                          )
                        }
                      })
                    }
                  </OwlCarousel>
                )
              }
              <div id="dotsId"></div>
            </div>
          </div>
        </section>
        <section className="emPage__detailsCourse">
          <div className={imageBanner.length === 0 ? 'em__body mt-3' : 'em__body'}>
            <div className="title_course">
              <h1>{productData.ticket_name}</h1>
            </div>
            <span className="size-12 color-text d-block">
            {
              parseInt(productData.business_type) === 1 ? 'Jam Buka' : 'Tanggal Event'
            }
            </span>
            {
              parseInt(productData.business_type) !== 1 ? (
                <div>
                  <span className="d-inline-block">
                    <svg className="w-16 h-16 dark:text-white mr-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 10h16m-8-3V4M7 7V4m10 3V4M5 20h14c.6 0 1-.4 1-1V7c0-.6-.4-1-1-1H5a1 1 0 0 0-1 1v12c0 .6.4 1 1 1Zm3-7h0v0h0v0Zm4 0h0v0h0v0Zm4 0h0v0h0v0Zm-8 4h0v0h0v0Zm4 0h0v0h0v0Zm4 0h0v0h0v0Z"/>
                    </svg>
                    {productData.start_date === productData.end_date ? productData.start_date : productData.start_date+' - '+productData.end_date}
                  </span>
                </div>
              ) : ''
            }
            <div>
              <span className="d-inline-block">
                {productData.jam_buka+' - '+productData.jam_tutup}
              </span>
            </div>
            <span className="size-12 color-text d-block mt-2">Termasuk Tiket Wahana</span>
            <div>
              <span className="d-inline-block">
                {productData.wahana_list}
              </span>
            </div>
          </div>
          <div className="dividar margin-t-20 margin-b-20"></div>
          <div className="em__requirements">
            <div className="title pb-0">
              <h3 className="size-16">Deskripsi</h3>
              <div className="py-2 color-secondary">
                {/* <p>
                  {productData.description}
                </p> */}
                <div dangerouslySetInnerHTML={{__html: productData.description}} />
              </div>
            </div>
          </div>
          <div className="em__pkLink accordion" id="accordionExample5">
            <ul className="nav__list with_border fullBorder mb-3">
              <li>
                <div id="headingOne-text">
                  <div className="item-link main_item" data-toggle="collapse" data-target="#collapseOne-text" aria-expanded="true" aria-controls="collapseOne-text">
                    <div className="group">
                      <div>
                        <span className="path__name">Highlight</span>
                      </div>
                    </div>
                    <div className="group">
                      <span className="short__name" />
                      <i className="tio-chevron_right -arrwo" />
                    </div>
                  </div>
                </div>
                <div id="collapseOne-text" className="collapse show" aria-labelledby="headingOne-text" data-parent="#accordionExample5">
                  <div className="card-body pt-1 px-2">
                    <p className="mb-0 size-15 color-text">
                      <div dangerouslySetInnerHTML={{__html: productData.highlight}} />
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div id="headingTwo-text">
                  <div className="item-link main_item" data-toggle="collapse" data-target="#collapseTwo-text" aria-expanded="false" aria-controls="collapseTwo-text">
                    <div className="group">
                      <div>
                        <span className="path__name">Syarat & Ketentuan</span>
                      </div>
                    </div>
                    <div className="group">
                      <span className="short__name" />
                      <i className="tio-chevron_right -arrwo" />
                    </div>
                  </div>
                </div>
                <div id="collapseTwo-text" className="collapse" aria-labelledby="headingTwo-text" data-parent="#accordionExample5">
                  <div className="em__pkLink">
                    <div className="card-body pt-1 px-2">
                      <p className="mb-0 size-15 color-text">
                        <div dangerouslySetInnerHTML={{__html: productData.term_condition}} />
                      </p>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div id="headingThree-text">
                  <div className="item-link main_item" data-toggle="collapse" data-target="#collapseThree-text" aria-expanded="false" aria-controls="collapseThree-text">
                    <div className="group">
                      <div>
                        <span className="path__name">Penukaran Tiket</span>
                      </div>
                    </div>
                    <div className="group">
                      <span className="short__name" />
                      <i className="tio-chevron_right -arrwo" />
                    </div>
                  </div>
                </div>
                <div  id="collapseThree-text" className="collapse"  aria-labelledby="headingThree-text"  data-parent="#accordionExample5">
                  <div className="card-body pt-1 px-2">
                    <p className="mb-0 size-15 color-text">
                      <div dangerouslySetInnerHTML={{__html: productData.syarat_penukaran_tiket}} />
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="em__certification">
            <div className="box">
              <div className="title">
                <h3 className="size-16">Info Penting</h3>
                <p>
                  <div dangerouslySetInnerHTML={{__html: productData.info_penting}} />
                </p>
              </div>
            </div>
          </div>
          <div className="em__requirements ">
            <div className="title pb-3">
              <h3 className="size-16">Lokasi</h3>
              <div className="">
                
                <div className="item rounded-8 border shadow-sm mt-2">
                  <div className="emhead_w p-2">
                    <div className="details_w" style={{width : '100%'}}>
                      <span className="d-block size-12 color-comment">
                        <svg className="w-16 h-16 dark:text-white mr-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                          <path fillRule="evenodd" d="M12 2a8 8 0 0 1 6.6 12.6l-.1.1-.6.7-5.1 6.2a1 1 0 0 1-1.6 0L6 15.3l-.3-.4-.2-.2v-.2A8 8 0 0 1 11.8 2Zm3 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" clipRule="evenodd"/>
                        </svg>
                        {productData.address+', '+productData.city+', '+productData.province}
                      </span>
                    </div>
                  </div>
                  <div className="dividar"></div>
                  <div className="embody_w p-2">
                    <div className="w-100">
                      <a href={'http://maps.google.co.uk/maps?q='+productData.longitude+','+productData.latitude} target="_blank" className="btn btn-sm bg-primary w-100 color-white text-center justify-content-center mr-2">
                        <svg className="w-16 h-16 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="currentColor" viewBox="0 0 24 24">
                          <path fillRule="evenodd" d="M12 2a1 1 0 0 1 .932.638l7 18a1 1 0 0 1-1.326 1.281L13 19.517V13a1 1 0 1 0-2 0v6.517l-5.606 2.402a1 1 0 0 1-1.326-1.281l7-18A1 1 0 0 1 12 2Z" clipRule="evenodd" />
                        </svg>
                        Tunjukan Lokasi
                      </a>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="em__requirements ">
            <div className="title pb-3">
              <h3 className="size-16">Tiket Sudah Termasuk</h3>
              <div className="">
                <div className="item rounded-8 border shadow-sm mt-2">
                  <div className="emhead_w pt-3 pr-2 pb-0">
                    <div className="details_w" style={{width : '100%'}}>
                      <div className="d-block size-12 color-comment">
                        <div dangerouslySetInnerHTML={{__html: productData.ticket_included}} />
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div ref={selectTicket} className="emTitle_co padding-20 bg-snow padding-b-10">
            <h2 className="size-16 weight-500 color-secondary mb-0">
              Pilihan Tiket
            </h2>
          </div>
          <div className="tab__line two_item">
            <ul className="nav nav-pills" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <a className="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-reguler" role="tab" aria-controls="pills-reguler" aria-selected="true">Reguler</a>
              </li>
              <li className="nav-item" role="presentation">
                <a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-group" role="tab" aria-controls="pills-group" aria-selected="false">Paket Bersama</a>
              </li>
            </ul>
          </div>
          <section className="components_page">
            <div className="tab-content" id="pills-tabContent">
              <div className="tab-pane fade show active" id="pills-reguler" role="tabpanel" aria-labelledby="pills-reguler-tab">
                <div className="emPage___profile accordion accordion_inset py-0 padding-20 bg-snow pb-2 pt-3" id="accordionExample-inset">
                  <div className="emBody__navLink">
                    <ul>
                    {
                      packageData.map((packageDetail, Key) => {
                        if(packageDetail.customer_group === 'reguler')
                        return (
                          <li className="item margin-b-10" key={Key}>
                            <div id="headingOne-inset" className="">
                              <div className="nav-link main_item d-flex flex-column pb-2 pt-3" style={{borderRadius : 0}}>
                                <div className="w-100 d-flex">
                                  <div className="media align-items-center">
                                    <div className="media-body my-auto">
                                      <div className="txt">
                                        <h3 className="mb-0 align-middle">
                                          <span className="d-inline-block size-12 weight-600">
                                            {packageDetail.package_name}
                                          </span>
                                        </h3>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="side_right ml-auto color-primary">
                                    <svg className="w-16 h-16 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="currentColor" viewBox="0 0 24 24">
                                      <path fillRule="evenodd" d="M5.617 2.076a1 1 0 0 1 1.09.217L8 3.586l1.293-1.293a1 1 0 0 1 1.414 0L12 3.586l1.293-1.293a1 1 0 0 1 1.414 0L16 3.586l1.293-1.293A1 1 0 0 1 19 3v18a1 1 0 0 1-1.707.707L16 20.414l-1.293 1.293a1 1 0 0 1-1.414 0L12 20.414l-1.293 1.293a1 1 0 0 1-1.414 0L8 20.414l-1.293 1.293A1 1 0 0 1 5 21V3a1 1 0 0 1 .617-.924ZM9 7a1 1 0 0 0 0 2h6a1 1 0 1 0 0-2H9Zm0 4a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2H9Zm0 4a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2H9Z" clipRule="evenodd" />
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div id={'collapseOne-inset-'+Key} className="collapse show" aria-labelledby="headingOne-inset" data-parent="#accordionExample-inset">
                              <div className="card-body bg-white pt-0">
                                <div className="w-100 border-top pt-2">
                                  <div className="d-flex align-items-center">
                                    <div className="d-flex flex-column">
                                      <span className="size-12">Harga Mulai Dari</span>
                                      <div className="size-14 weight-600 color-secondary">
                                        <span className="color-text">Rp</span> {priceSplitter(packageDetail.start_price)}
                                      </div>
                                      <span className="size-12 color-medium">Tiket tersedia</span>
                                    </div>
                                    <div className="d-flex flex-column justify-content-center ml-auto">
                                      <button className="btn btn-md btn__icon bg-primary color-white text-center" onClick={()=>{showPackageMdl(Key)}}>
                                        Pesan
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <span className="size-12 color-microsoft mt-2">
                                  </span>
                                </div>
                              </div>
                            </div>
                          </li>
                        )
                      })
                    }
                    </ul>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="pills-group" role="tabpanel" aria-labelledby="pills-group-tab">
                <div className="emPage___profile accordion accordion_inset py-0 padding-20 bg-snow pb-2 pt-3" id="accordionExample-inset">
                  <div className="emBody__navLink">
                    <ul>
                    {
                      packageData.map((packageDetail, Key) => {
                        if(packageDetail.customer_group === 'grup')
                        return (
                          <li className="item margin-b-10" key={Key}>
                            <div id="headingOne-inset" className="">
                              <div className="nav-link main_item d-flex flex-column pb-2 pt-3" style={{borderRadius : 0}}>
                                <div className="w-100 d-flex">
                                  <div className="media align-items-center">
                                    <div className="media-body my-auto">
                                      <div className="txt">
                                        <h3 className="mb-0 align-middle">
                                          <span className="d-inline-block size-12 weight-600">
                                            {packageDetail.package_name}
                                          </span>
                                        </h3>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="side_right ml-auto color-primary">
                                    <svg className="w-16 h-16 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="currentColor" viewBox="0 0 24 24">
                                      <path fillRule="evenodd" d="M5.617 2.076a1 1 0 0 1 1.09.217L8 3.586l1.293-1.293a1 1 0 0 1 1.414 0L12 3.586l1.293-1.293a1 1 0 0 1 1.414 0L16 3.586l1.293-1.293A1 1 0 0 1 19 3v18a1 1 0 0 1-1.707.707L16 20.414l-1.293 1.293a1 1 0 0 1-1.414 0L12 20.414l-1.293 1.293a1 1 0 0 1-1.414 0L8 20.414l-1.293 1.293A1 1 0 0 1 5 21V3a1 1 0 0 1 .617-.924ZM9 7a1 1 0 0 0 0 2h6a1 1 0 1 0 0-2H9Zm0 4a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2H9Zm0 4a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2H9Z" clipRule="evenodd" />
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div id={'collapseOne-inset-'+Key} className="collapse show" aria-labelledby="headingOne-inset" data-parent="#accordionExample-inset">
                              <div className="card-body bg-white pt-0">
                                <div className="w-100 border-top pt-2">
                                  <div className="d-flex align-items-center">
                                    <div className="d-flex flex-column">
                                      <span className="size-12">Harga Mulai Dari</span>
                                      <div className="size-14 weight-600 color-secondary">
                                        <span className="color-text">Rp</span> {priceSplitter(packageDetail.start_price)}
                                      </div>
                                      <span className="size-12 color-medium">Tiket tersedia</span>
                                    </div>
                                    <div className="d-flex flex-column justify-content-center ml-auto">
                                      <button className="btn btn-md btn__icon bg-primary color-white text-center" onClick={()=>{showPackageMdl(Key)}}>
                                        Pesan
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <span className="size-12 color-microsoft mt-2">
                                  </span>
                                </div>
                              </div>
                            </div>
                          </li>
                        )
                      })
                    }
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="padding-t-60 bg-white">
            <div className="em_footerinner border-top">
              <div className="emfo_button __withIcon env-pb d-flex align-items-center justify-content-between">
                <div className="">
                  <span className="d-block size-10 color-comment">Harga Mulai</span>
                  <span className="size-18 weight-600 color-primary">
                    <span className="color-text">Rp</span> {priceSplitter(productData.start_price)}
                  </span>
                </div>
                <button className="btn btn-sm bg-primary color-white text-center justify-content-center px-4" onClick={goToTicket}>
                  Pilih Tiket
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
      
      <div className="modal transition-bottom screenFull defaultModal confirm__transfer mdlladd__rate fade" id="mdl_package_price" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header padding-l-20 padding-r-20 text-left">
              <div className="itemProduct_sm">
                <h1 className="size-16 weight-600 color-secondary m-0">Paket Harga Tiket</h1>
              </div>
              <div className="absolute right-0 padding-r-20">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <i className="tio-clear" />
                </button>
              </div>
            </div>
            {mdlBodyPackage}
          </div>
        </div>
      </div>

      <div className="modal transition-bottom -inside screenFull defaultModal mdlladd__rate fade" id="mdllButtons_share" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content rounded-15">
            <div className="modal-body rounded-15 p-0">
              <div className="emBK__buttonsShare icon__share padding-20">
                <button type="button" className="btn" data-sharer="facebook" data-hashtag="hashtag" data-url="https://orinostudio.com/nepro/">
                  <div className="icon bg-facebook rounded-10">
                    <i className="tio-facebook_square" />
                  </div>
                </button>
                <button type="button" className="btn" data-sharer="telegram" data-title="Checkout Nepro!" data-url="https://orinostudio.com/nepro/" data-to="+44555-5555">
                  <div className="icon bg-telegram rounded-10">
                    <i className="tio-telegram" />
                  </div>
                </button>
                <button type="button" className="btn" data-sharer="skype" data-url="https://orinostudio.com/nepro/" data-title="Checkout Nepro!">
                  <div className="icon bg-skype rounded-10">
                    <i className="tio-instagram" />
                  </div>
                </button>
                <button type="button" className="btn" data-sharer="twitter" data-title="Checkout Nepro!" data-hashtags="pwa, Nepro, template, mobile, app, shopping, ecommerce" data-url="https://orinostudio.com/nepro/">
                  <div className="icon bg-twitter rounded-10">
                    <i className="tio-twitter" />
                  </div>
                </button>
                <button type="button" className="btn" data-sharer="whatsapp" data-title="Checkout Nepro!" data-url="https://orinostudio.com/nepro/">
                  <div className="icon bg-whatsapp rounded-10">
                    <i className="tio-whatsapp_outlined" />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductWisataDetail;