
import forge from 'node-forge';
import JSEncrypt from 'jsencrypt';
import CryptoJS from 'crypto-js';

const encryptAESKey = (AESKey, publicKey) => {
  const publicKeyPem = forge.pki.publicKeyFromPem(publicKey);
  const rsaEncryptedAesKey = publicKeyPem.encrypt(AESKey, 'RSA-OAEP');
  return forge.util.encode64(rsaEncryptedAesKey);
}

const encryptText = (text, key, iv) => {
  const cipher = forge.cipher.createCipher('AES-CBC', key);
  cipher.start({ iv: iv });
  cipher.update(forge.util.createBuffer(text));
  cipher.finish();
  const encrypted = cipher.output;
  return encrypted.toHex();
}

export const encryptPayload = (payload) => {
  // Generate a random symmetric key for AES encryption
  const symmetricKey = forge.random.getBytesSync(16);
  const iv = forge.random.getBytesSync(16);

  // server's public RSA key (replace this with the actual key)
  const serverPublicKey = process.env.REACT_APP_PUBLIC_KEY 

  // Encrypt the symmetric key with the server's public RSA key
  const encryptedSymmetricKey = encryptAESKey(symmetricKey, serverPublicKey)

  // Encrypt the payload using the generated symmetric key
  const encryptedPayload = encryptText(JSON.stringify(payload), symmetricKey, iv)

  return { esk: encryptedSymmetricKey.toString(), 
            rep: forge.util.encode64(iv + encryptedPayload) }
};

export const encryptData = (payload) => {
// var encryptData = function encrypt(dataObject, publicKey) {
  var serverPublicKey = process.env.REACT_APP_PUBLIC_KEY 
  var key = forge.random.getBytesSync(16);
  // convert data to a json string
  var dataAsString = JSON.stringify(payload);
  // encrypt the data symmetrically 
  // (the cryptojs library will generate its own 256bit key!!)
  var aesEncrypted = CryptoJS.AES.encrypt(dataAsString, key);
  // get the symmetric key and initialization vector from
  // (hex encoded) and concatenate them into one string
  var aesKey = aesEncrypted.key + ":::" + aesEncrypted.iv;
  // the data is base64 encoded 
  var encryptedMessage = aesEncrypted.toString();

  // we create a new JSEncrypt object for rsa encryption
  var rsaEncrypt = new JSEncrypt();
  
  // we set the public key (which we passed into the function)
  rsaEncrypt.setPublicKey(serverPublicKey);
  // now we encrypt the key & iv with our public key
  var encryptedKey = rsaEncrypt.encrypt(aesKey);
  // and concatenate our payload message
  var payload = encryptedKey + ":::" + encryptedMessage;
  return payload;
};

export const encryptAsymData = (data) => {
  var encrypt = new JSEncrypt(); // Create a new instance of the JSEncrypt library.
  var publicKey = process.env.REACT_APP_PUBLIC_KEY  // Define the public key. This is the public key generated earlier.
  encrypt.setPublicKey(publicKey); // Set the public key for the encryption library.
  var encrypted = encrypt.encrypt(data); // Use the encrypt method of the library to encrypt the data.
  
  return encrypted;
}