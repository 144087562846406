import axios from "axios";

const baseUrl = process.env.REACT_APP_BASEURL
const token = localStorage.getItem('token')
const config = {
  headers: { 
              'Authorization': `Bearer ${token}`,
              // 'Content-Type': 'application/json', 
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": "true",
              "Access-Control-Max-Age": "1800",
              "Access-Control-Allow-Headers": "content-type",
              "Access-Control-Allow-Methods": "PUT, POST, GET, DELETE, PATCH",
            }
}

const configNoAuth = {
  headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": "false",
              "Access-Control-Max-Age": "1800",
              "Access-Control-Allow-Headers": "Origin, X-Api-Key, X-Requested-With, Content-Type, Accept, Authorization",
              "Access-Control-Allow-Methods": "PUT, POST, GET, DELETE, PATCH, OPTION",
            }
}

export const getVaList = async () => {
  const vaList = await axios.get(`${baseUrl}/get-va`, config)
  return vaList
}

export const getEwalletList = async () => {
  const ewalletList = await axios.get(`${baseUrl}/get-ewallet`, config)
  return ewalletList
}

export const payment = async (formData) => {
  const reqPayment = await axios.post(`${baseUrl}/payment`, formData, config)
  return reqPayment
}

export const checkPromo = async (formData) => {
  const reqPromo = await axios.post(`${baseUrl}/check-promo`, formData, config)
  // console.log(reqPromo)
  return reqPromo
}

export const orderList = async () => {
  const reqOrderList = await axios.get(`${baseUrl}/order`, config)
  return reqOrderList
}

export const getOrderArchived = async () => {
  const reqOrderArchived = await axios.get(`${baseUrl}/order-archived`, config)
  return reqOrderArchived
}

export const getOrderDetail = async (orderId) => {
  const reqOrderList = await axios.get(`${baseUrl}/order-detail/${orderId}`, config)
  return reqOrderList
}

export const reqSendEticket = async (orderId) => {
  const reqSend = await axios.get(`${baseUrl}/send-eticket/${orderId}`, config)
  return reqSend
}

export const paymentOlahraga = async (formData) => {
  const reqPayment = await axios.post(`${baseUrl}/payment-sport`, formData, config)
  return reqPayment
}

export const getPaymentGateway = async () => {
  const pgList = await axios.get(`${baseUrl}/get-payment-gateway`, config)
  return pgList.data
}

export const checkPromoWisata = async (formData) => {
  try{
    const reqPromo = await axios.post(`${baseUrl}/check-promo-wisata`, formData, config)
    return reqPromo.data
  }catch(err){
    return err.response.data
  }
}

export const paymentWisata = async (formData) => {
  try{
    const reqPayment = await axios.post(`${baseUrl}/payment-wisata`, formData, config)
    return reqPayment.data
    // console.log(reqPayment)
  }catch(err){
    // console.log(err)
    return err.response.data
  }
}

export const paymentWisataEncrypted = async (formData) => {
  try{
    const reqPayment = await axios.post(`${baseUrl}/payment-encrypted`, formData, config)
    return reqPayment
  }catch(err){
    return err.response.data
  }
}