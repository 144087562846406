import React, {useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import moment from 'moment'
import {productFee} from '../../Api/Product'
import {getPaymentGateway, checkPromoWisata, paymentWisata} from '../../Api/Transaction';
import {getPoinUser} from '../../Api/Auth';

import {priceSplitter} from '../../Tools/Tools'
import {encryptAsymData} from '../../Tools/EncryptDecrypt'

function CheckoutWisata(){
  const navigate = useNavigate()
  const location = useLocation()
  const [imageUrl, setImageUrl] = useState(process.env.REACT_APP_ASSETS_PRODUCT_WISATA)
  const [paymentLogoUrl, setPaymentLogoUrl] = useState(process.env.REACT_APP_ASSETS_PAYMENT_LOGO)
  const [loaderDisplayBg0, setLoaderDisplayBg0] = useState("none")
  const [loaderDisplay, setLoaderDisplay] = useState("flex")

  const slug = useRef(location.state.slug)
  const [productData, setProductData] = useState(location.state.productData)
  const [dataOrder, setDataOrder] = useState(location.state.dataOrder)
  const [feeData, setFeeData] = useState([])

  const imageBanner = productData.gallery_photo.split(',');
  const qtyTicket = useRef(location.state.qtyTicket)
  const userFullname = localStorage.getItem('fullname') ===  null ? '' : localStorage.getItem('fullname')
  const userPhone = localStorage.getItem('phone_number') ===  null ? '' : (localStorage.getItem('phone_number') ===  'null' ? '' : localStorage.getItem('phone_number'))
  const userEmail = localStorage.getItem('email') ===  null ? '' : localStorage.getItem('email')
  const userPoin = useRef(0)
  const [usePoin, setUsePoin] = useState(false)

  const btnPay = useRef(null)
  const btnPayLoading = useRef(null)
  const divAlertPayment = useRef(null)
  const [alertPayment, setAlertPayment] = useState('')

  const [insuranceInclude, setInsurancenInclude] = useState(false)

  const [promoCode, setPromoCode] = useState(null)
  const [usePromo, setUsePromo] = useState(false)
  const btnPromo = useRef(null)
  const btnPromoLoading = useRef(null)
  const promoApplied = useRef(null)
  const promoUsedAmount = useRef(0)
  const [promoDisabled, setPromoDisabled] = useState(false)
  const alertPromoCodeFail = useRef(null)
  const alertPromoCodeSuccess = useRef(null)

  const final_amount = useRef(0)
  const ttlBasePrice = useRef(0)
  const feePlatformChild = useRef(0)
  const feePlatformAdult = useRef(0)
  const feePlatformRegulerGrup = useRef(0)
  const tax = useRef(0)
  const feeDevice = useRef(0)

  const [insuranceAmount, setInsuranceAmount] = useState(0)
  const [voucherType, setVoucherType] = useState(null)
  const [voucherValue, setVoucherValue] = useState(0)
  const [voucherAmount, setVoucherAmount] = useState(0)
  const [feePaymentgateway, setFeePaymentgateway] = useState(0)
  const [ttlTaxFee, setTtlTaxFee] = useState(0)
  const [finalAmount, setFinalAmount] = useState(0)
  const [poinReward, setPoinReward] = useState(0)
  const [poinUsed, setPoinUsed] = useState(0)

  const [paymentGatewayList, setPaymentGatewayList] = useState([])
  const [mdlBodyPaymentMethod, setMdlBodyPaymentMethod] = useState([])
  const paymentActive = useRef(null)
  const channelCodeActive = useRef(null)
  const feePaymentAmountActive = useRef(0)
  const feePaymentPercentageActive = useRef(0)
  const feePaymentVatActive = useRef(0)
  const logoPaymentActive = useRef('')
  const paymentGatewaySelected = useRef(0)
  const [divPaymentMethod, setDivPaymentMethod] = useState((<button type="button" className="btn btn-outline-primary w-100 color-primary text-center justify-content-center" data-toggle="modal" data-target="#mdl_payment_method">
                                                              Pilih pembayaran
                                                            </button>))

  const getUserPoin = () => {
    getPoinUser().then((res) => {
      if(res.responseCode == 200000){
        setLoaderDisplay('none')
        userPoin.current = res.responseData.poin_reward
      }
    })
  }

  const reqDetailFee = async () => {
    const formData = new FormData()
    formData.append('slug', productData.slug_url)
    formData.append('originPriceChild', productData.originPriceChild)
    formData.append('originPriceAdult', productData.originPriceAdult)
    formData.append('originPriceRegulerGrup', productData.originPriceRegulerGrup)
    productFee(formData).then((res) => {
      if(res.responseCode === 200000){
        setFeeData(res.responseData)
      }
    })
  }

  const reqPaymentGateway = async () => {
    getPaymentGateway().then((res) => {
      if(res.responseCode === 200000){
        setPaymentGatewayList(res.responseData)
      }
    })
  }

  const fetchDataPaymentMethod = () => {
    setMdlBodyPaymentMethod((
      <ul>
        {
          paymentGatewayList.length !== 0 ? 
          paymentGatewayList.payment_method_group.map((paymentGroup, groupKey) => {
            var groupName = paymentGroup['payment_method'] === 'VA' ? 'Virtual Account' : (paymentGroup['payment_method'] === 'E-WALLET' ? 'E-Wallet' : paymentGroup['payment_method'])
            return (
              <li className="item margin-b-10">
                <div id={"headingOne-inset"+groupKey}>
                  <div className="nav-link main_item" style={{borderRadius : 0}} data-toggle="collapse" data-target={"#collapseOne-inset"+groupKey} aria-expanded="false" aria-controls="collapseOne-inset2">
                    <div className="media align-items-center" style={{width : '40%'}}>
                      <div className="media-body my-auto">
                        <div className="txt">
                          <h3 className='mb-0'>{groupName}</h3>
                          {/* <span className="size-12 color-medium"> (+Tambahan biaya)</span> */}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end" style={{width : '50%'}}>
                      {
                        paymentGroup['payment_method'] === 'VA' ? (
                          <div>
                            <img className="mr-1" src={paymentLogoUrl+'/banks/bca-logo.svg'} alt="" height={'9px'} width={'auto'}/>
                            <img className="mr-1" src={paymentLogoUrl+'/banks/bni-logo.svg'} alt="" height={'9px'} width={'auto'}/>
                            <img className="mr-1" src={paymentLogoUrl+'/banks/bri-logo.svg'} alt="" height={'9px'} width={'auto'}/>
                          </div>
                        ) : (paymentGroup['payment_method'] === 'QRIS' ? (
                          <img className="mr-1" src={paymentLogoUrl+'/qris/qris-logo.svg'} alt="" height={'12px'} width={'auto'}/>
                        ) : (
                          <div>
                            <img className="mr-1" src={paymentLogoUrl+'/e-wallet/ovo-logo.svg'} alt="" height={'9px'} width={'auto'}/>
                            <img className="mr-1" src={paymentLogoUrl+'/e-wallet/dana-logo.svg'} alt="" height={'9px'} width={'auto'}/>
                            <img className="mr-1" src={paymentLogoUrl+'/e-wallet/shopeepay-logo.svg'} alt="" height={'9px'} width={'auto'}/>
                          </div>
                        ))
                      }
                    </div>
                    <div className="side_right">
                      <i className="tio-chevron_right -arrwo"></i>
                    </div>
                  </div>
                </div>
                <div id={"collapseOne-inset"+groupKey} className={paymentActive.current === paymentGroup['payment_method'] ? 'collapse show' : 'collapse'} aria-labelledby={"headingOne-inset"+groupKey} data-parent="#accordionExample-inset-payment">
                  <div className="card-body bg-white py-0">
                    <section className="emSection__payment px-0 pt-3 mt-1">
                      <div className="credite_saved itemSingle">
                        {
                          paymentGatewayList.payment_method.map((paymentMethod, paymentMethodKey) => {
                            if(paymentMethod.payment_method === paymentGroup['payment_method']){
                              var feeAmount = parseInt(paymentMethod.transaction_fee_amount)
                              var feePercentage = parseFloat(paymentMethod.transaction_fee_percentage)
                              var vat = parseFloat(paymentMethod.vat_percentage)
                              var logo = paymentLogoUrl+'/'+paymentMethod.thumbnail_logo
                              
                              return (
                                <button 
                                  key={paymentMethodKey}
                                  type="button" 
                                  className={channelCodeActive.current === paymentMethod.channel_code ? "btn item item_credit selected p-3" : "btn item item_credit p-3"} 
                                  onClick={() => paymentSelected(paymentGroup['payment_method'], paymentMethod.channel_code, feeAmount, feePercentage, vat, logo)
                                }>
                                  <div className="media align-items-center">
                                    <div className="icon">
                                      <img src={logo} alt="" width={50} height={'auto'}/>
                                    </div>
                                    <div className="media-body">
                                      <div className="">
                                        <span className="size-14 text-body d-inline-block mr-1">{paymentMethod.gateway_name}</span>
                                        {
                                          parseInt(feeData.fee_payment_gateway_include_price) === 0 ? (
                                            <span className="size-12 color-medium d-inline-block"> 
                                               (+Biaya {
                                                parseInt(paymentMethod.transaction_fee_amount) !== 0 ? 
                                                priceSplitter(paymentMethod.transaction_fee_amount) : (
                                                  parseFloat(paymentMethod.transaction_fee_percentage) !== 0 ? 
                                                  (paymentMethod.transaction_fee_percentage+'%') : (
                                                    (paymentMethod.vat_percentage+'%')
                                                  )
                                                )
                                              })
                                            </span>
                                          ) : ''
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </button>
                              )
                            }
                          })
                        }
                      </div>
                    </section>
                  </div>
                </div>
              </li>
            )
          }) : ''
        }
      </ul>
    ))
  }

  const paymentSelected = (group, channelCode, feeAmount, feePercentage, vat, logo) => {
    paymentActive.current = group
    channelCodeActive.current = channelCode
    feePaymentAmountActive.current = feeAmount
    feePaymentPercentageActive.current = feePercentage
    feePaymentVatActive.current = vat
    logoPaymentActive.current = logo
    
    fetchDataPaymentMethod()
  }

  const fixPaymentSelected = () => {
    paymentGatewaySelected.current = 1
    setDivPaymentMethod(
      <div className="border-primary px-1 rounded d-flex align-items-center justify-content-between" onClick={() => changePaymentSelected()}>
        <div className="">
          <span className="d-block text-capitalize">
            {
              paymentActive.current === 'VA' ? 'Virtual Account'+' - '+channelCodeActive.current : (paymentActive.current === 'E-WALLET' ? 'E-Wallet'+' - '+channelCodeActive.current.slice(3) : paymentActive.current)
            }
          </span>
          <span className="size-12 color-medium d-inline-block">
            (+Biaya  
              <span className="d-inline-block ml-1">{
                parseInt(feePaymentAmountActive.current) !== 0 ? 
                priceSplitter(feePaymentAmountActive.current)+" + VAT" : (
                  parseFloat(feePaymentPercentageActive.current) !== 0 ? 
                  (feePaymentPercentageActive.current+'%')+" + VAT" : (
                    (feePaymentVatActive.current+'%')
                  )
                )
              }</span>)
          </span>
        </div>
        <img src={logoPaymentActive.current} alt="" height={'15px'} width={'auto'}/>
      </div>
    )

    window.$('#mdl_payment_method').modal('hide');
  }

  const changePaymentSelected = () => {
    window.$('#mdl_payment_method').modal('show');
  }

  const insuranceHandler = () => {
    if(insuranceInclude){
      setInsurancenInclude(false)
      setInsuranceAmount(0)
    }else{
      setInsurancenInclude(true)
      setInsuranceAmount(feeData.insurance_amount*(dataOrder[0].qtyTicketAdult+dataOrder[0].qtyTicketChild+dataOrder[0].qtyTicketRegulerGrup))
    }
  }

  const promoHandler = (e) => {
    e.preventDefault()
    const formData = new FormData()
    btnPromo.current.className = 'd-none'
    btnPromoLoading.current.className = 'd-block'
    alertPromoCodeFail.current.className = "d-none"
    alertPromoCodeSuccess.current.className = "d-none"
    formData.append('code_voucher', promoCode)
    checkPromoWisata(formData).then((res) => {
      if(res.responseCode === 200000){
        setUsePromo(true)
        setVoucherType(res.responseData.data_promo.type)
        setVoucherValue(res.responseData.data_promo.value)
        var tempVoucherAmount = 0
        if(res.responseData.data_promo.type === 'percentage'){
          tempVoucherAmount = (res.responseData.data_promo.value/100)*ttlBasePrice.current
        }else if(res.responseData.data_promo.type === 'amount'){
          tempVoucherAmount = res.responseData.data_promo.value
        }

        setVoucherAmount(tempVoucherAmount)
        setPromoDisabled(true)
        alertPromoCodeSuccess.current.className = "d-block"
        alertPromoCodeFail.current.className = "d-none"
        promoApplied.current.className = "d-block"
      }else{
        alertPromoCodeFail.current.className = "d-block"
        alertPromoCodeSuccess.current.className = "d-none"
      }

      btnPromo.current.className = 'd-block'
      btnPromoLoading.current.className = 'd-none'
    })
  }

  const rmPromoHandler = () => {
    setVoucherType(null)
    setVoucherValue(0)
    setVoucherAmount(0)
    setUsePromo(false)
    setPromoCode(null)
    promoUsedAmount.current = 0
    setPromoDisabled(false)
    alertPromoCodeSuccess.current.className = "d-none"
    promoApplied.current.className = "d-none"
  }

  const usePoinHandler = () => {
    if(usePoin){
      setUsePoin(false)
      userPoin.current = poinUsed
      setPoinUsed(0)
    }else{
      setUsePoin(true)
      setPoinUsed(userPoin.current)
      userPoin.current = 0
    }
  }

  const calcAmount = () => {
    ttlBasePrice.current = dataOrder[0].ttlPriceAdult+dataOrder[0].ttlPriceChild+dataOrder[0].ttlPriceRegulerGrup+insuranceAmount
    var tempTtlBasePrice = ttlBasePrice.current
    if(parseInt(feeData.fee_platform_include_price) === 0){
      feePlatformChild.current = feeData.fee_platform_percentage_child/100*(dataOrder[0].ttlPriceChild)
      feePlatformAdult.current = feeData.fee_platform_percentage_adult/100*(dataOrder[0].ttlPriceAdult)
      feePlatformRegulerGrup.current = feeData.fee_platform_percentage_reguler_grup/100*(dataOrder[0].ttlPriceRegulerGrup)
    }

    if(parseInt(feeData.tax_include_price) === 0){
      tax.current = feeData.tax_percentage/100*(tempTtlBasePrice)
    }

    if(parseInt(feeData.fee_device_include_price) === 0){
      feeDevice.current = feeData.fee_percentage_device/100*(tempTtlBasePrice)
    }

    var tempFinalAmount = tempTtlBasePrice+(feePlatformChild.current+feePlatformAdult.current+feePlatformRegulerGrup.current)
    tempFinalAmount += tax.current
    tempFinalAmount += feeDevice.current

    var tempFeePaymentGateway = 0
    var vat = 0
    if(feePaymentAmountActive.current !== 0){
      tempFeePaymentGateway = feePaymentAmountActive.current
      if(feePaymentVatActive.current !== 0){
        vat = feePaymentVatActive.current/100*(tempFeePaymentGateway)
        tempFeePaymentGateway += vat
      }
    }else if(feePaymentPercentageActive.current !== 0){
      tempFeePaymentGateway = feePaymentPercentageActive.current/100*(tempFinalAmount)
      if(feePaymentVatActive.current !== 0){
        vat = feePaymentVatActive.current/100*(tempFeePaymentGateway)
        tempFeePaymentGateway += vat
      }
    }else if(feePaymentVatActive.current !== 0){
      tempFeePaymentGateway = feePaymentVatActive.current/100*(tempFinalAmount)
    }
    setFeePaymentgateway(Math.ceil(tempFeePaymentGateway))

    var poin = 0
    poin += feeData.poin_percentage_child*feePlatformChild.current
    poin += feeData.poin_percentage_adult*feePlatformAdult.current
    poin += feeData.poin_percentage_reguler_grup*feePlatformRegulerGrup.current

    var tempTaxFee = (feePlatformChild.current+feePlatformAdult.current+feePlatformRegulerGrup.current)+tax.current+feeDevice.current+Math.ceil(tempFeePaymentGateway)
    tempFinalAmount += Math.ceil(tempFeePaymentGateway)
    setTtlTaxFee(tempTaxFee)
    setFinalAmount(tempFinalAmount-poinUsed-voucherAmount)
    setPoinReward(poin)

    // console.log('--------------------------')
    // console.log('---- calculation data ----')
    // console.log('qtyTicketChild : '+dataOrder[0].qtyTicketChild)
    // console.log('qtyTicketAdult : '+dataOrder[0].qtyTicketAdult)
    // console.log('qtyTicketRegulerGrup : '+dataOrder[0].qtyTicketRegulerGrup)
    // console.log('originPriceChild : '+dataOrder[0].originPriceChild)
    // console.log('originPriceAdult : '+dataOrder[0].originPriceAdult)
    // console.log('originPriceRegulerGrup : '+dataOrder[0].originPriceRegulerGrup)
    // console.log('feePlatformChild : '+priceSplitter(feePlatformChild.current))
    // console.log('feePlatformAdult : '+priceSplitter(feePlatformAdult.current))
    // console.log('feePlatformRegulerGrup : '+priceSplitter(feePlatformRegulerGrup.current))
    // console.log('insurance : '+priceSplitter(insuranceAmount))
    // console.log('feePaymentGateway : '+priceSplitter(tempFeePaymentGateway))
    // console.log('feeDevice : '+priceSplitter(feeDevice.current))
    // console.log('tax : '+priceSplitter(tax.current))
    // console.log('ttlTaxFee : '+priceSplitter(tempTaxFee))
    // console.log('ttlBasePrice : '+priceSplitter(ttlBasePrice.current))
    // console.log('voucherType : '+voucherType)
    // console.log('voucherValue : '+priceSplitter(voucherValue))
    // // console.log('voucherAmount : '+priceSplitter(Math.ceil(tempVoucherAmount)))
    // console.log('voucherAmount : '+priceSplitter(voucherAmount))
    // console.log('poinUsed : '+priceSplitter(poinUsed))
    // console.log('finalAmount : '+priceSplitter(tempFinalAmount-poinUsed-voucherAmount))
    // console.log('poinReward : '+poin)
  }

  const confirmPayment = () => {
    if(paymentActive.current !== null){
      window.$('#mdl_confirm_payment').modal('show')
    }else{
      setDivPaymentMethod((
        <div>
          <span className="size-12 color-microsoft d-block mt-0 mb-2">*Pilih metode pembayaran terlebih dahulu</span>
          <button type="button" className="btn btn-outline-primary w-100 text-center justify-content-center" data-toggle="modal" data-target="#mdl_payment_method">
            Pilih pembayaran
          </button>
        </div>
      ))
    }
  }

  const paymentHandler = () => {
    btnPay.current.className = 'd-none'
    btnPayLoading.current.className = 'd-block'
    divAlertPayment.current.className = 'd-none'

    var arr_data = {
      'select_date' : dataOrder[0].selectDate,
      'id_price_adult' : dataOrder[0].idPriceAdult,
      'id_price_child' : dataOrder[0].idPriceChild,
      'id_price_reguler_grup' : dataOrder[0].idPriceRegulerGrup,
      'qty_ticket_adult' : dataOrder[0].qtyTicketAdult,
      'qty_ticket_child' : dataOrder[0].qtyTicketChild,
      'qty_ticket_reguler_grup' : dataOrder[0].qtyTicketRegulerGrup,
      'insurance_amount' : insuranceAmount,
      'voucher_code' : promoCode,
      'poin_used' : poinUsed,
      'channel_code' : channelCodeActive.current,
      'poin_reward' : poinReward,
      'fee_payment_gateway' : feePaymentgateway,
      'tax' : tax.current,
      'final_amount' : finalAmount
    }

    var arr_json = JSON.stringify(arr_data)
    const encryptedData = encryptAsymData(arr_json)

    const formData = new FormData()
    formData.append('payload', encryptedData)

    paymentWisata(formData).then((res) => { 
      btnPayLoading.current.className = 'd-none'
      btnPay.current.className = 'd-block'
      if(res.responseCode !== 200000){
        btnPay.current.className = 'd-block'
        btnPayLoading.current.className = 'd-none'
        divAlertPayment.current.className = 'd-block'
      }else{
        window.$('#mdl_confirm_payment').modal('hide')
        if(paymentActive.current === 'VA'){
          navigate('/payment', {state: { 
                                          accountNumber : res.responseData.account_number,
                                          bankCode : res.responseData.bank_code,
                                          expectedAmount : res.responseData.expected_amount,
                                          expirationDate : res.responseData.expiration_date,
                                          event_name : productData.ticket_name,
                                          ticket_name : dataOrder[0].customerGroup.toUpperCase(),
                                          topik_name : 'Wisata'
                                        }})
        }else if(paymentActive.current === 'E-WALLET'){
          navigate('/payment-ewallet', {state: {
                                                  expectedAmount : res.responseData.expected_amount,
                                                  desktopWebUrl : res.responseData.desktop_web_checkout_url,
                                                  mobileWebUrl : res.responseData.mobile_web_checkout_url,
                                                  mobileDeeplinkUrl : res.responseData.mobile_deeplink_checkout_url,
                                                  channelCode : channelCodeActive.current.slice(3),
                                                  event_name : productData.ticket_name,
                                                  ticket_name : dataOrder[0].customerGroup.toUpperCase(),
                                                  topik_name : 'Wisata'
                                                }})
        }else if(paymentActive.current === 'QRIS'){
          navigate('/payment-qris', {state: {
                                              qr_id : res.responseData.qr_id,
                                              qr_string : res.responseData.qr_string,
                                              expectedAmount : res.responseData.expected_amount,
                                              event_name : productData.ticket_name,
                                              ticket_name : dataOrder[0].customerGroup.toUpperCase(),
                                              topik_name : 'Wisata'
                                            }})
        }
      }
    })
  }

  useEffect(() => {
    calcAmount()
  }, [feeData, insuranceAmount, channelCodeActive.current, voucherAmount, poinUsed])
  
  const toProductDetail = (slug) => {
    navigate('/product-wisata-detail/'+slug)
  }

  useEffect(() => {
    fetchDataPaymentMethod()
  }, [paymentGatewayList])

  useEffect(() => {
    reqPaymentGateway()
    reqDetailFee()
    getUserPoin()
  },[])

  return (
    <div id="wrapper" className="vh-100">
      <section className="em_loading" id="loader" style={{display: loaderDisplay}}>
        <div className="spinner-grow bg-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </section>
      <section className="em_loading" id="loaderBg0" style={{display: loaderDisplayBg0, backgroundColor: 'rgb(41 46 52 / 0%)'}}>
        <div className="spinner-grow bg-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </section> 

      <div id="content">
        <header className="main_haeder header-sticky multi_item shadow-sm">
          <div className="em_side_right">
            <span className="rounded-circle d-flex align-items-center cursor-pointer" onClick={() => toProductDetail(slug.current)}>
              <i className="tio-chevron_left size-24 color-text" />
              <span className="color-text size-14">Back</span>
            </span>
          </div>
          <div className="title_page">
            <div className="page_name">Ticket Order
            </div>
          </div>
        </header>
        <section className="emPage__basket npPkg__basket default" style={{paddingBottom : '100px'}}>
          <div className="emPage___profile accordion accordion_inset" id="accordionExample-inset">
            <div className="emBody__navLink">
              <ul>
                <li className="item margin-b-5">
                  <div id="headingOne-inset">
                    <div className="nav-link main_item" data-toggle="collapse" data-target="#collapseOne-inset" aria-expanded="false" aria-controls="collapseOne-inset">
                      <div className="media align-items-center">
                        <div className="ico">
                          <img className="imgList w-60 h-51 rounded-4" style={{'object-fit' : 'cover', 'cursor' : 'default'}} src={imageUrl+productData.client+'/'+imageBanner[0]} alt="" />
                        </div>
                        <div className="media-body my-auto">
                          <div className="txt" style={{'cursor' : 'default'}}>
                            <h3 className="mb-0">{productData.event_name}</h3>
                            <span className="size-14 color-primary">{productData.ticket_name}</span>
                          </div>
                        </div>
                      </div>
                      <div className="side_right">
                        <i className="tio-chevron_right -arrwo"></i>
                      </div>
                    </div>
                  </div>
                  <div id="collapseOne-inset" className="collapse" aria-labelledby="headingOne-inset" data-parent="#accordionExample-inset">
                    <div className="card-body pt-0 pb-2">
                      <div className="pt-2 border-top">
                        <span className="d-inline-block">
                          <svg className="w-16 h-16 text-gray-800 dark:text-white mr-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                          </svg>
                          {productData.jam_buka+' - '+productData.jam_tutup}
                        </span>
                      </div>
                      <div className="mt-1">
                        <span className="d-inline-block">
                          <svg className="w-16 h-16 dark:text-white mr-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                            <path fill-rule="evenodd" d="M12 2a8 8 0 0 1 6.6 12.6l-.1.1-.6.7-5.1 6.2a1 1 0 0 1-1.6 0L6 15.3l-.3-.4-.2-.2v-.2A8 8 0 0 1 11.8 2Zm3 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" clip-rule="evenodd"/>
                          </svg>
                          {productData.address+', '+productData.city+', '+productData.province}
                        </span>
                      </div>
                      <div className="mt-2 border-top pt-2">
                        <span className="size-14 mb-1">Info Penting</span>
                        <div className="color-text ml-4">
                          <div dangerouslySetInnerHTML={{__html: productData.info_penting}} />
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="bg-snow">
            <div className="emTitle_co padding-r-20 padding-l-20 padding-t-20">
              <h2 className="size-14 weight-600 color-secondary">Detail Pesanan</h2>
            </div>
            <div className="em_masonry__layout bg-snow em_list_layout pt-0">
              <div className="item em_item_product item_list d-block mb-0 shadow-sm">
                {
                  dataOrder[0].customerGroup === 'reguler' ? (dataOrder[0].customerType === 'dewasa_anak' ? (
                    <div>
                      <div className="itemSwitch d-flex py-1 align-items-center">
                        <div>
                          <span className="size-14 text-muted">Jumlah Tiket</span>
                        </div>
                        <div className="em_toggle ml-auto">
                          <span className="size-14">{dataOrder[0].qtyTicketAdult+dataOrder[0].qtyTicketChild} pax</span><span className="color-text"></span>
                        </div>
                      </div>
                      <div className="itemSwitch d-flex py-1 align-items-center">
                        <div>
                          <span className="size-14 text-muted">Dewasa</span>
                        </div>
                        <div className="em_toggle ml-auto">
                          <span className="size-14">{dataOrder[0].qtyTicketAdult} pax</span><span className="color-text"></span>
                        </div>
                      </div>
                      <div className="itemSwitch d-flex py-1 align-items-center">
                        <div>
                          <span className="size-14 text-muted">Anak</span>
                        </div>
                        <div className="em_toggle ml-auto">
                          <span className="size-14">{dataOrder[0].qtyTicketChild} pax</span><span className="color-text"></span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="itemSwitch d-flex py-1 align-items-center">
                        <div>
                          <span className="size-14 text-muted">Jumlah Tiket</span>
                        </div>
                        <div className="em_toggle ml-auto">
                          <span className="size-14">{qtyTicket.current} pax</span><span className="color-text"></span>
                        </div>
                      </div>
                    </div>
                    )) : (
                      <div>
                        <div className="itemSwitch d-flex py-1 align-items-center">
                          <div>
                            <span className="size-14">Jumlah Tiket Rombongan</span>
                          </div>
                          <div className="em_toggle ml-auto">
                            <span className="size-14">{dataOrder[0].qtyTicketRegulerGrup} pax</span><span className="color-text"></span>
                          </div>
                        </div>
                        {
                          dataOrder[0].freeTicket > 0 ? (
                            <div className="itemSwitch d-flex py-1 align-items-center">
                              <div>
                                <span className="size-14">Free Tiket</span>
                              </div>
                              <div className="em_toggle ml-auto">
                                <span className="size-14">{dataOrder[0].freeTicket} pax</span><span className="color-text"></span>
                              </div>
                            </div>
                          ) : ''
                        }
                      </div>
                  )
                }
                <div className="itemSwitch d-flex py-1 align-items-center">
                  <div>
                    <span className="size-14 text-muted">Tanggal Dipilih</span>
                  </div>
                  <div className="em_toggle ml-auto">
                    <span className="size-14">{moment(dataOrder[0].selectDate).format('ll')}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-snow">
            <div className="emTitle_co padding-r-20 padding-l-20 padding-t-20">
              <h2 className="size-14 weight-600 color-secondary">Detail Pemesan</h2>
            </div>
            <div className="em_masonry__layout bg-snow em_list_layout pt-0">
              <div className="item em_item_product item_list d-block mb-0 shadow-sm">
                <span className="d-block font-weight-bold text-capitalize">{userFullname}</span>
                <span className="d-block color-text">{userPhone}</span>
                <span className="d-block color-text">{userEmail}</span>
              </div>
            </div>
          </div>

          <div className="bg-snow">
            <div className="emTitle_co padding-r-20 padding-l-20 padding-t-20">
              <h2 className="size-14 weight-600 color-secondary">Biaya Lain-Lain</h2>
            </div>
            <div className="em_masonry__layout bg-snow em_list_layout pt-0">
              <div className="item em_item_product item_list d-block mb-0 shadow-sm">
                {
                  parseInt(feeData.insurance_amount) !== 0? (
                    <div className="itemSwitch d-flex py-1 align-items-center">
                      <div>
                        <span className="size-14" data-toggle="modal" data-target="#mdl_detail_insurance">
                          Asuransi Wisata
                          <svg className="w-16 h-16 ml-1 mb-1 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                            <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm9.008-3.018a1.502 1.502 0 0 1 2.522 1.159v.024a1.44 1.44 0 0 1-1.493 1.418 1 1 0 0 0-1.037.999V14a1 1 0 1 0 2 0v-.539a3.44 3.44 0 0 0 2.529-3.256 3.502 3.502 0 0 0-7-.255 1 1 0 0 0 2 .076c.014-.398.187-.774.48-1.044Zm.982 7.026a1 1 0 1 0 0 2H12a1 1 0 1 0 0-2h-.01Z" clip-rule="evenodd"/>
                          </svg>
                        </span>
                        <span className="size-12 color-text d-block">Rp {priceSplitter(feeData.insurance_amount)} /pax</span>
                      </div>
                      <div className="em_toggle ml-auto">
                        <button type="button" className="btn btn-toggle toggle_md" data-toggle="button" aria-pressed="false" autoComplete="off" id="toggleTwo" onClick={insuranceHandler}>
                          <div className="handle"/>
                        </button>
                      </div>
                    </div>
                  ) : ''
                }
                <div className="itemSwitch d-flex py-1 align-items-center">
                  <div>
                    <span className="size-14">Biaya Lainnya</span>
                  </div>
                  <div className="em_toggle ml-auto">
                    <span className="size-14">{priceSplitter(ttlTaxFee)}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="bg-snow">
            <div className="emTitle_co padding-r-20 padding-l-20 padding-t-20">
              <h2 className="size-14 weight-600 color-secondary">Pilih Metode Pembayaran</h2>
            </div>
            <div className="em_masonry__layout bg-snow em_list_layout pt-0">
              <div className="item em_item_product item_list d-block mb-0 shadow-sm">
                {divPaymentMethod}
              </div>
            </div>
          </div>

          <div className="bg-snow">
            <div className="emTitle_co padding-r-20 padding-l-20 padding-t-20">
              <h2 className="size-14 weight-600 color-secondary">Kode Promo / Referral</h2>
            </div>
            <div className="em_masonry__layout bg-snow em_list_layout pt-0">
              <div className="item em_item_product item_list d-block mb-0 shadow-sm pt-2 pb-3">
                <div className="itemSwitch">
                  <span className="size-12 color-text d-block pb-1">Gunakan kode promo atau referral untuk dapat tambahan diskon</span>
                  <form className="d-flex" onSubmit={promoHandler}>
                    <input disabled={promoDisabled} type="text" className="form-control size-14" placeholder="Masukan kode..." value={promoCode === null ? '' : promoCode} onChange={(e) => setPromoCode(e.target.value)} required/>
                    <div className="em_toggle ml-auto pl-2">
                      <div ref={btnPromo} className="d-block">
                        <button disabled={promoDisabled} type="submit" className="btn btn-primary size-14">
                          Cek
                        </button>
                      </div>
                      <div ref={btnPromoLoading} className="d-none">
                      <button className="btn btn-primary size-14" type="button" disabled>
                        <span className="spinner-border spinner-border-sm color-white" role="status" aria-hidden="true" />
                        <span className="sr-only">Loading...</span>
                      </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div ref={alertPromoCodeFail} className="d-none">
                  <span className="size-12 color-red mt-1">Kode promo tidak berlaku.</span>
                </div>
                <div ref={alertPromoCodeSuccess} className="d-none">
                  <span className="size-12 color-blue mt-1">Kode promo berhasil digunakan.</span>
                </div>
                <div ref={promoApplied} className="d-none">
                  <div className="d-flex justify-content-between align-items-center p-2 rounded-5 border">
                    <div className="d-flex flex-column">
                      <span className="color-comment">Promo Apply</span>
                      <span className="color-comment size-12 color-green">
                        {
                          voucherType === 'PERCENTAGE' ? (
                            '- '+voucherValue+'%'
                          ) : (
                            '- Rp '+priceSplitter(voucherAmount)
                          )
                        }
                      </span>
                    </div>
                    <div className="d-flex align-items-center">
                      <span className="size-14 color-green d-block">- Rp {priceSplitter(voucherAmount)}</span>
                      <span onClick={() => {rmPromoHandler()}}>
                        <svg className="w-22 h-22 ml-1 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18 17.94 6M18 18 6.06 6" />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-snow padding-b-20">
            <div className="emTitle_co padding-r-20 padding-l-20 padding-t-20">
              <h2 className="size-14 weight-600 color-secondary">Gunakan Poin Untuk Pembayaran</h2>
            </div>
            <div className="em_masonry__layout bg-snow em_list_layout pt-0">
              <div className="item em_item_product item_list d-block mb-0 bg-gradient shadow-sm">
                <div className="itemSwitch d-flex py-1 align-items-center">
                  <div>
                    <span className="size-14">Poinmu Saat Ini</span>
                    <img src="assets/star.png" width={'auto'} height={14} alt="" className="ml-1"/>
                    <span className="size-12 color-text d-block weight-700">{priceSplitter(userPoin.current)}</span>
                  </div>
                  <div className="em_toggle ml-auto">
                    <button type="button" className="btn btn-toggle toggle_md" data-toggle="button" aria-pressed="false" autoComplete="off" id="togglePoin" onClick={usePoinHandler}>
                      <div className="handle"/>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        <div className="buttons__footer" style={{bottom : 0}}>
          <div className="size-16 weight-600 color-primary mb-2" style={{'cursor' : 'default'}} data-toggle="modal" data-target="#mdl_detail_harga">
          <span className="d-block weight-400 size-12 color-secondary">Total Pembayaran <span className="text-muted d-none">(*Diluar biaya payment gateway)</span></span>
            <span className="color-text">Rp</span> {priceSplitter(finalAmount)}
            <svg className="w-16 h-16 ml-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 9-7 7-7-7"/>
            </svg>
          </div>
          <div className="env-pb text-center">
            <button type="button" className="btn bg-primary w-100 color-white text-center justify-content-center px-5" onClick={confirmPayment}>
              <b>Bayar</b>
            </button>
          </div>
        </div>
      </div>
      
      <div className="modal transition-bottom screenFull defaultModal confirm__transfer mdlladd__rate fade" id="mdl_payment_method" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header padding-l-20 padding-r-20 text-left">
              <div className="itemProduct_sm">
                <h1 className="size-18 weight-600 color-secondary m-0">Metode Pembayaran</h1>
              </div>
              <div className="absolute right-0 padding-r-20">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <i className="tio-clear" />
                </button>
              </div>
            </div>
            <div className="modal-body border-top overflow-auto" style={{height : '65vh', backgroundColor : '#f7f7f8'}}>
              <div className="emPage___profile accordion accordion_inset py-0 mt-3" id="accordionExample-inset-payment">
                <div className="emBody__navLink">
                  <ul>
                    {mdlBodyPaymentMethod}
                  </ul>
                </div>
              </div>
              
              <div className="h-80"></div>
            </div>
            <div className="modal-footer pb-1">
              <div className="pt-0" style={{bottom : 0, width : '100%'}}>
                <div className="env-pb text-center">
                  {
                    paymentActive.current !== null ? (
                      <p className="color-text size-13 text-center my-2">
                        Kamu akan transfer menggunakan <b>{paymentActive.current !== 'QRIS' ? paymentActive.current+' - '+(channelCodeActive.current.includes('_') ? channelCodeActive.current.slice(3) : channelCodeActive.current) : paymentActive.current}</b>
                      </p>
                    ) : ''
                  }
                  <div className="d-block mt-2">
                    <button disabled={channelCodeActive.current === null ? true : false} className="btn bg-primary rounded-pill btn__default" onClick={() => fixPaymentSelected()} >
                      <span className="color-white d-inline-block mr-5"><b>Pilih</b></span>
                      <div className="icon rounded-circle">
                        <i className="tio-chevron_right"/>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="modal transition-bottom screenFull defaultModal confirm__transfer mdlladd__rate fade" id="mdl_detail_harga" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header padding-l-20 padding-r-20 text-left">
              <div className="itemProduct_sm">
                <h1 className="size-16 weight-600 color-secondary m-0">Detail Harga</h1>
              </div>
              <div className="absolute right-0 padding-r-20">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <i className="tio-clear" />
                </button>
              </div>
            </div>
            <div className="modal-body">
              <div className="emPage__basket npPkg__basket default py-3">
                <div className="detailsTotally p-0">
                  <ul>
                    <li>
                      <span>Jumlah Pembelian Tiket</span>
                      <span className="font-weight-normal">{dataOrder[0].qtyTicketAdult+dataOrder[0].qtyTicketChild+dataOrder[0].qtyTicketRegulerGrup} Pax</span>
                    </li>
                    {
                      dataOrder[0].customerGroup === 'reguler' ? (dataOrder[0].customerType === 'dewasa_anak' ? (
                        <div>
                          <li>
                            <span>Qty tiket (Adult)</span>
                            <span className="font-weight-normal">{dataOrder[0].qtyTicketAdult} Tiket</span>
                          </li>
                          <li>
                            <span>Qty tiket (Child)</span>
                            <span className="font-weight-normal">{dataOrder[0].qtyTicketChild} Tiket</span>
                          </li>
                        </div>
                      ) : (
                        <li>
                          <span>Qty tiket</span>
                          <span className="font-weight-normal">{dataOrder[0].qtyTicketRegulerGrup} Tiket</span>
                        </li>
                      )) : (
                        <li>
                          <span>Qty tiket</span>
                          <span className="font-weight-normal">{dataOrder[0].qtyTicketRegulerGrup} Tiket</span>
                        </li>)
                    }
                    {
                      dataOrder[0].customerGroup === 'reguler' ? (dataOrder[0].customerType === 'dewasa_anak' ? (
                        <div>
                          <li>
                            <span>Harga tiket (Adult)</span>
                            <span className="font-weight-normal">Rp {priceSplitter(dataOrder[0].originPriceAdult)}</span>
                          </li>
                          <li>
                            <span>Harga tiket (Child)</span>
                            <span className="font-weight-normal">Rp {priceSplitter(dataOrder[0].originPriceChild)}</span>
                          </li>
                        </div>
                      ) : (
                        <li>
                          <span>Harga tiket</span>
                          <span className="font-weight-normal">Rp {priceSplitter(dataOrder[0].originPriceRegulerGrup)}</span>
                        </li>
                      )) : (
                        <li>
                          <span>Harga tiket</span>
                          <span className="font-weight-normal">Rp {priceSplitter(dataOrder[0].originPriceRegulerGrup)}</span>
                        </li>)
                    }
                    <li>
                      <span>Total Harga Tiket</span>
                      <span className="font-weight-normal">Rp {priceSplitter((dataOrder[0].qtyTicketAdult*dataOrder[0].originPriceAdult)+(dataOrder[0].qtyTicketChild*dataOrder[0].originPriceChild)+(dataOrder[0].qtyTicketRegulerGrup*dataOrder[0].originPriceRegulerGrup))}</span>
                    </li>
                    <div className="dividar" />
                    <li>
                      <span>Biaya Penanganan</span>
                      <span className="font-weight-normal text-muted">Rp {priceSplitter(ttlTaxFee)}</span>
                    </li>
                    <li>
                      <span>Asuransi</span>
                      <span className="font-weight-normal text-muted">Rp {priceSplitter(insuranceAmount)}</span>
                    </li>
                    <li>
                      <span>Potongan Kode Promo</span>
                      <span className="font-weight-normal text-success">- Rp {priceSplitter(voucherAmount)}</span>
                    </li>
                    <li>
                      <span>Potongan Poinmu</span>
                      <span className="font-weight-normal text-success">- Rp {priceSplitter(poinUsed)}</span>
                    </li>
                    <div className="dividar" />
                    <li className="mb-1">
                      <span className="size-16 text_total">Total Pembayaran</span>
                      <span className="number_total size-16">Rp {priceSplitter(finalAmount)}</span>
                    </li>
                    <li className=" d-flex justify-content-end text-end">
                      <span className="mb-1 color-text size-12 mt-0">
                        <img src={window.location.origin + "/assets/star.png"} width={'auto'} height={14} alt="" className="mr-1 margin-b-9"/>
                        Poin
                        <span className="ml-1 text-primary size-12 d-inline">{poinReward}</span>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="modal transition-bottom screenFull defaultModal confirm__transfer mdlladd__rate fade" id="mdl_detail_insurance" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header padding-l-20 padding-r-20 text-left">
              <div className="itemProduct_sm">
                <h1 className="size-16 weight-600 color-secondary m-0">Perlindungan Ekstra</h1>
              </div>
              <div className="absolute right-0 padding-r-20">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <i className="tio-clear" />
                </button>
              </div>
            </div>
            <div className="modal-body overflow-auto" style={{height : '70vh'}}>
              <div className="emPage__basket npPkg__basket default py-3">
                <div className="detailsTotally p-0">
                  <div dangerouslySetInnerHTML={{__html: productData.benefit}} />
                  <table className="table table-bordered table-striped text-center mt-2">
                    <thead>
                      <th className="w-50 p-1">Manfaat</th>
                      <th className="w-50 p-1">Kompensasi</th>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="p-1">-</td>
                        <td className="p-1">-</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="modal transition-bottom screenFull defaultModal confirm__transfer mdlladd__rate fade" id="mdl_confirm_payment" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header padding-l-20 padding-r-20 text-left">
              <div className="itemProduct_sm">
                <h1 className="size-18 weight-600 color-secondary m-0">Konfirmasi Pembayaran</h1>
              </div>
              <div className="absolute right-0 padding-r-20">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <i className="tio-clear" />
                </button>
              </div>
            </div>
            <div className="modal-body border-top overflow-auto" style={{backgroundColor : '#f7f7f8'}}>
              <div className="padding-t-20 padding-b-20">
                <div className="trans__number margin-t-20 padding-b-30">
                  <h3 className="color-primary">
                    <span className="color-text">Rp </span> 
                    {priceSplitter(finalAmount)}
                  </h3>
                  <p>Nominal Transfer</p>
                </div>
                {
                  paymentActive.current !== null ? (
                    <p className="color-text size-13 text-center mb-0">Kamu akan transfer <span className="color-secondary">Rp {priceSplitter(finalAmount)}</span> menggunakan <b>{paymentActive.current !== 'QRIS' ? paymentActive.current+' - '+(channelCodeActive.current.includes('_') ? channelCodeActive.current.slice(3) : channelCodeActive.current) : paymentActive.current}</b>.</p>
                  ) : ''
                }
              </div>
            </div>
            <div className="modal-footer pb-1">
              <div className="pt-2" style={{bottom : 0, width : '100%'}}>
                <span ref={divAlertPayment} className="d-none">
                  <span className="color-red size-13 text-center d-block mb-2">Transaksi error </span>
                </span>
                <div ref={btnPay} className="d-block">
                  <button type="button" className="btn btn-loading bg-primary w-100 color-white text-center justify-content-center px-5" onClick={paymentHandler}>
                    <b>Bayar Sekarang</b>
                  </button>
                </div>
                <div ref={btnPayLoading} className="d-none">
                  <button type="button" className="btn bg-primary w-100 color-white text-center justify-content-center px-5" disabled>
                    <div className="icon rounded-circle">
                      <span className="spinner-border spinner-border-sm text-white" role="status" aria-hidden="true" />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CheckoutWisata;