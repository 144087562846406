import React, {useEffect, useState} from 'react'
import { useNavigate, useLocation } from "react-router-dom"

import {getOrderArchived} from '../../Api/Transaction'
import {checkUserToken} from '../../Tools/Tools'

function History(){
  const navigate = useNavigate()
  const location = useLocation()
  const backPage = location.state.backPage
  const [loaderDisplay, setLoaderDisplay] = useState("flex")

  const [orderArchived, setOrderArchived] = useState([])
  const [viewOrderArchived, setViewOrderArchived] = useState()

  const reqOrderArchived = async () => {
    getOrderArchived().then((res) => {
      if(res.status == 200){
        setOrderArchived(res.data)
        setLoaderDisplay('none')
      }
    })
  }
  
  const toDetailOrder=(id)=>{
    navigate('/detail-order-wisata', {state:{orderId:id, backPage:'order'}});
  }

  useEffect(() => {
    if(orderArchived.length === 0){
      setViewOrderArchived(
        <div className="w-100 text-center color-text">
          <span>Kamu belum memiliki riwayat pembelian tiket.</span>
        </div>
      )
    }else{
      setViewOrderArchived(
        orderArchived.map((order, Key) => {
          var coverUrl = process.env.REACT_APP_ASSETS_PRODUCT_WISATA
          return (
            <div onClick={() => toDetailOrder(orderArchived[Key].uuid)} className="item shadow-sm bg-lightgrey">
              <div className="emhead_w">
                <div className="details_w" style={{width : '80%'}}>
                  <span className="d-block size-12 font-weight-bolder">
                    #{orderArchived[Key].transaction_number}
                  </span>
                  <span className="d-block size-14 font-weight-bolder">{orderArchived[Key].business_brand_name+" ("+orderArchived[Key].ticket_name+")"}</span>
                  {/* <span className="d-block size-12 color-comment">{orderArchived[Key].location_name+', '+orderArchived[Key].address+', '+orderArchived[Key].kota+', '+orderArchived[Key].province}</span> */}
                    <span className="d-block size-12 color-comment">{orderArchived[Key].city+', '+orderArchived[Key].province}</span>
                </div>
                <div className="icon_img">
                  <img
                    src={coverUrl+orderArchived[Key].id_client+'/'+orderArchived[Key].gallery_photo}
                    alt=""
                  />
                </div>
              </div>
              <div className="dividar pt-2"></div>
              <div className="embody_w">
                <div className="size-12 color-comment w-100 d-flex justify-content-between">
                  <span className="">Tanggal Pembelian</span>
                  <span className="font-weight-normal">{orderArchived[Key].order_date}</span>
                </div>
              </div>
              <div className="embody_w mt-1">
                <div className="size-12 color-comment w-100 d-flex justify-content-between">
                  <span className="">Tiket Untuk</span>
                  <span className="font-weight-normal">{orderArchived[Key].selection_date} - {orderArchived[Key].qty} pax</span>
                </div>
              </div>
            </div>
          )
        })
      )
    }
  }, [orderArchived])

  useEffect(() => {
    if(!checkUserToken()){
      navigate('/login')
    }else{
      reqOrderArchived()
      setTimeout(function() { 
        setLoaderDisplay('none')
      }, 1000)
    }
  },[])

  return (
    <div id="wrapper" className="vh-100">
      <section className="em_loading" id="loader" style={{display: loaderDisplay}}>
        <div className="spinner-grow bg-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </section>
      <div id="content" className="overflow-auto">
        <header className="header_tab shadow-sm">
          <div className="main_haeder multi_item">
            <div className="em_side_right">
              <a
                className="rounded-circle d-flex align-items-center text-decoration-none"
                href={backPage}
              >
                <i className="tio-chevron_left size-24 color-text" />
                <span className="color-text size-14">Back</span>
              </a>
            </div>
            <div className="title_page">
              <span className="page_name">Riwayat Pesanan</span>
            </div>
          </div>
        </header>
        <section className="components_page pt-5 mt-2 emPage__billsWallet">
          <div className="tab-content padding-20" id="pills-tabContent">
            <div className="emBk__bills">
              {viewOrderArchived}
            </div>
          </div>
        </section>
      </div>
    </div>

  )
}
export default History;