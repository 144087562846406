import React, {useEffect, useRef, useState} from 'react'
import { useNavigate} from "react-router-dom"

import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import CountDown from '../Tools/CountDown'

import {productCategory, productList, productPromotionAtHome, productWisataList} from '../Api/Product';
import {getPoin} from '../Api/Auth';
import {orderList} from '../Api/Transaction'
import {priceSplitter} from '../Tools/Tools'

function Main(props){
  const navigate = useNavigate()
  const assetsEvent = process.env.REACT_APP_ASSETS_PRODUCT_EVENT
  const assetsSport = process.env.REACT_APP_ASSETS_PRODUCT_SPORT
  const assetsWisata = process.env.REACT_APP_ASSETS_PRODUCT_WISATA

  const [width, setWidth] = useState(window.innerWidth);
  const [carouselItem, setCarouselItem] = useState()
  const [loaderDisplay, setLoaderDisplay] = useState("flex")
  const userFullname = localStorage.getItem('fullname')
  const fullName = userFullname === null ? '' : userFullname.split(" ")
  const userFirstname = userFullname === null ? '' : fullName[0]
  const userPhoneNumber = localStorage.getItem('phone_number')
  const userPicture = localStorage.getItem('profile_picture')
  const userPoin = useRef(0)
  const [profile, setProfile] = useState([(
                                            <div className="input_SaerchDefault d-inline-block w-full bg-snow p-2 rounded-8" >
                                              <div className="em_profile_user">
                                                <div className="media">
                                                  <a href="/profile">
                                                    <div className="bg-primary icon-rounded text-white">
                                                      <b>J</b>
                                                    </div>
                                                  </a>
                                                  <div className="media-body">
                                                    <div className="txt">
                                                      <b>Hai, Juara Tiket </b>
                                                      <span className="color-comment text-nowrap">Silahkan login ke akunmu ...</span>
                                                    </div>
                                                  </div>
                                                  <div className="media-body">
                                                    <div className="txt">
                                                      <a href="/login" className="btn bg-primary rounded-10 text-white px-4 float-right">Masuk</a>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )])
  const [orderPending, setOrderPending] = useState([])
  const [classNamePending, setClassNamePending] = useState([])

  const [eventCategory, setEventCategory] = useState([])
  const [productPromotion, setProductPromotion] = useState([])
  const [productEvent, setProductEvent] = useState([])
  const [productSport, setProductSport] = useState([])
  const [productWahana, setProductWahana] = useState([])

  const setCarouselItemNumber = () => {
    if(width < 365){
      setCarouselItem(1)
    }else if(width >= 365 && width < 526){
      setCarouselItem(2)
    }else if(width >= 526 && width < 768){
      setCarouselItem(3)
    }else if(width >= 768){
      setCarouselItem(2)
    }
  }

  const getUserPoin = async () => {
    if (localStorage.getItem('token')){
      getPoin().then((res) => {
        reqOrderPending()
        if(res.status === 200){
          userPoin.current = res.data
          checkUserToken()
        }
      })
    }else{
      reqOrderPending()
      checkUserToken()
    }
  }

  const checkUserToken = () => {
    if (localStorage.getItem('token')) {
      setProfile([(
                    <div className="input_SaerchDefault d-inline-block w-full bg-snow p-2 rounded-8" >
                      <div className="em_profile_user">
                        <div className="media">
                          <a href="/profile">
                            {
                              userPicture === '' || userPicture === 'null' || !userPicture ? (
                                <div className="bg-primary icon-rounded text-white text-uppercase">
                                  <b>{userFullname.substring(0, 1)}</b>
                                </div>
                              ) : (
                                <img className="imgList w-40 h-40 rounded-circle mr-2" src={userPicture} alt=""/>
                              )
                            }
                          </a>
                          <div className="media-body">
                            <div className="txt">
                              <span className="d-block weight-700 size-14 text-capitalize text-logo-gradient">Hai, {userFirstname} </span>
                              <span className="color-comment size-14 text-nowrap d-block">
                                {userPhoneNumber === null || userPhoneNumber === 'null' || userPhoneNumber === '' ? '-' : userPhoneNumber}
                              </span>
                            </div>
                          </div>
                          <div className="media-body border-left px-2">
                            <div className="h-auto">
                              <span className="d-block size-14">
                                Poin Reward
                                <img src="assets/star.png" width={'auto'} height={14} alt="" className="ml-1"/>
                              </span>
                              <span className="size-14 color-comment mb-1">{priceSplitter(userPoin.current)}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )])
    }
  }

  const reqProductCategory = async () => {
    productCategory().then((res) => {
      if(res.status === 200){
        reqProduct()
        setEventCategory(res.data)
      }
    })
  }

  const reqProduct = async () => {
    var productPromotionList = 0
    var eventList = 0
    var olahragaList = 0
    var wahanaList = 0
    productPromotionAtHome().then((res) => {
      if(res.status === 200){
        productPromotionList = 1
        setProductPromotion(res.data)
        if(wahanaList === 1 && eventList === 1 && olahragaList === 1){
          setLoaderDisplay('none')
        }
      }
    })

    // productList('all', 'popular', 1, 1, 'all', 0, 0, 1).then((res) => {
    //   if(res.status === 200){
    //     wahanaList = 1
    //     setProductWahana(res.data)
    //     if(productPromotionList === 1 && eventList === 1 && olahragaList === 1){
    //       setLoaderDisplay('none')
    //     }
    //   }
    // })

    productWisataList().then((res) => {
      if(res.responseCode === 200000){
        wahanaList = 1
        setProductWahana(res.responseData)
        if(productPromotionList === 1 && eventList === 1 && olahragaList === 1){
          setLoaderDisplay('none')
        }
      }
    })

    productList('all', 'popular', 1, 1, 'all', 0, 0, 2).then((res) => {
      if(res.status === 200){
        eventList = 1
        setProductEvent(res.data)
        if(productPromotionList === 1 && wahanaList === 1 && olahragaList === 1){
          setLoaderDisplay('none')
        }
      }
    })

    productList('all', 'popular', 1, 1, 'all', 0, 0, 3).then((res) => {
      if(res.status === 200){
        olahragaList = 1
        setProductSport(res.data)
        if(productPromotionList === 1 && wahanaList === 1 && eventList === 1){
          setLoaderDisplay('none')
        }
      }
    })
  }

  const reqOrderPending = async () => {
    if (localStorage.getItem('token')) {
      orderList().then((res) => {
        if(res.status === 200){
          reqProductCategory()
          setOrderPending(res.data['pending'])
          setClassNamePending(res.data['pending'].length === 1 ? 'em_masonry__layout bg-snow em_list_layout pt-0 pb-3' : 'em_masonry__layout bg-snow em_list_layout pt-0')
        }
      })
    }else{
      reqProductCategory()
    }
  }
  
  const toDetailOrder=(id)=>{
    // navigate('/detail-order',{state:{orderId:id, backPage:'home'}});
    navigate('/detail-order-wisata', {state:{orderId:id, backPage:'home'}});
  }
  
  const toProduct=(category)=>{
    console.log(category)
    if(parseInt(category) === 1){
      navigate('/product-wisata');
    }else{
      navigate('/product', {state:{keyword:null, productCategory:category}});
    }
  }
  
  const toProductDetail = (slug, businessType = 0) => {
    navigate('/product-detail/'+slug, {state:{businessType:businessType}})
  }
  
  const toProductWisataDetail = (slug, businessType = 0) => {
    navigate('/product-wisata-detail/'+slug, {state:{businessType:businessType}})
  }

  useEffect(() => {
    setCarouselItemNumber()
    getUserPoin()
    reqProductCategory()
  },[])

  return (
    // productEvent.length !== 0 ? (
      <div className="bg-snow">
        <section className="em_loading" id="loader" style={{display: loaderDisplay}}>
          <div className="spinner-grow bg-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </section>
        <section className="change_colorSearch bg-white padding-b-20" style={{paddingTop : '85px'}}>
          {profile}
        </section>
        <section className="emCategories__learning margin-b-10 bg-white pb-3">
          <OwlCarousel 
            items={3}  
            className="owl-carousel owl-theme owlCategories_learning" 
            stagePadding={25}  
            margin={10} 
          >
            {
              eventCategory.map((eventCat, Key) => {
                return (
                  <div className="item" key={Key}>
                    <div onClick={() => {toProduct(eventCategory[Key].id_product)}} className={'box__ele cursor-pointer '+eventCategory[Key].pwa_color}>
                      <div className="icon">
                        <img src={'assets/img/products/'+eventCategory[Key].pwa_icon} alt="" />
                      </div>
                      <div className="txt">
                        <h2>{eventCategory[Key].product_name}</h2>
                        <p>+{eventCategory[Key].count_product}</p>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </OwlCarousel>
        </section>
        {
          orderPending.length !== 0 ? (
            <div className="bg-snow">
              <div className="emTitle_co padding-r-20 padding-l-20">
                <h2 className="size-14 weight-600 color-secondary">Selesaikan segera pembayaranmu !</h2>
              </div>
              <OwlCarousel 
                items={1}  
                className="owl-carousel owl-theme" 
                stagePadding={5}  
                margin={5} 
              >
                {
                  orderPending.map((event, Key) => {
                    var timeFormat = new Date(orderPending[Key].expiration_date)
                    const timeIsoString = timeFormat.toISOString()
                      return (
                        <div key={Key} className={classNamePending} onClick={() => toDetailOrder(orderPending[Key].uuid)}>
                          <div className="item em_item_product item_list d-block mb-0 shadow-sm">
                            <span className="d-block font-weight-bold text-capitalize">
                              Tiket {orderPending[Key].business_brand_name}
                            </span>
                            <span className="flashCircle" />
                            <span className="d-block color-text">{orderPending[Key].qty+' Pax - '+orderPending[Key].ticket_name}</span>
                            <span className="d-block color-text">Rp {priceSplitter(orderPending[Key].amount)}</span>
                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <span className="d-block color-text">Batas Akhir Pembayaran</span>
                              </div>
                              <div>
                                <CountDown time={timeIsoString}/>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                  })
                }
              </OwlCarousel>
            </div>
            ) : ('')
        }
        <section className="em_swiper_products emCoureses__grid margin-b-10 bg-white py-3">
          <div className="em_title_swiper">
            <div className="txt">
              <h5 className="size-16 mb-1">Populer & Promo</h5>
              <p>Kunjungi acara populer dengan promo menarik</p>
            </div>
          </div>
          <div className="banner_swiper padding-t-10">
            <OwlCarousel 
              items={1} 
              margin={10}
              stagePadding={30}
              loop={true}
              autoplay={true}
              autoplayHoverPause={true}
              autoplayTimeout={3000}
              className="owl-carousel owl-theme em_owl_swipe" 
              style={{minHeight : '50px'}}
            >
              {
                productPromotion.map((promotion, Key) => {
                  var coverUrl = productPromotion[Key].product_name === 1 || productPromotion[Key].product_name === '1' ? process.env.REACT_APP_ASSETS_PRODUCT_WISATA+productPromotion[Key].client_name : (productPromotion[Key].product_name === 2 || productPromotion[Key].product_name === '2' ? process.env.REACT_APP_ASSETS_PRODUCT_EVENT+productPromotion[Key].id_event : process.env.REACT_APP_ASSETS_PRODUCT_SPORT+productPromotion[Key].id_event)
                  const imageBanner = productPromotion[Key].foto_banner.split(',');
                  return (
                    <div onClick={() => toProductDetail(productPromotion[Key].slug_url)} className="item em_item">
                      <div className="em_cover_img">
                        <img src={coverUrl+'/'+imageBanner[0]} alt="" />
                        <div className="em_text">
                          <div className="margin-b-5">
                            <div className="em_category px-1 mb-1">{productPromotion[Key].product_category}</div>
                            <h4 className="offer_txt">{productPromotion[Key].event_name}</h4>
                          </div>
                          <span className="em_link">
                            <span>Detail</span>
                            <i className="tio-chevron_right" />
                          </span>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </OwlCarousel>
          </div>
        </section>
        <section className="em_swiper_products emCoureses__grid margin-b-10 bg-white py-3">
          <div className="em_title_swiper">
            <div className="txt">
              <h5 className="size-16 mb-1">Event Hits</h5>
              <p>Kunjungi event terpopuler saat ini.</p>
            </div>
            <div className="item_link">
              <span className="color-blue cursor-pointer" onClick={() => {toProduct(2)}}>View all</span>
            </div>
          </div>
          <div className="em_bodyCarousel padding-t-10">
            <OwlCarousel 
            items={carouselItem} 
            autoWidth={true}
            margin={10}
            stagePadding={5} 
            className="owl-carousel owl-theme owlThemeCorses owl-loaded" 
            style={{minHeight : '50px'}}>
              {
                productEvent.map((event, Key) => {
                  if(Key < 5){
                    return (
                      <div className="item" key={Key}>
                        <div className="em_itemCourse_grid">
                          <div onClick={() => toProductDetail(productEvent[Key].slug_url)} className="card">
                            <div className="cover_card">
                              <img src={assetsEvent+productEvent[Key].id_event+'/'+productEvent[Key].foto_banner} className="card-img-top" alt="img" />
                            </div>
                            <div className="card-body p-2">
                              <div className="head_card mb-1">
                                <span>
                                  {productEvent[Key].start_date === productEvent[Key].end_date ? productEvent[Key].start_date : productEvent[Key].start_date+' - '+productEvent[Key].end_date}
                                </span>
                              </div>
                              <div className="head_card mb-1 h-40 d-flex align-items-start">
                                <h5 className="card-title" style={{'color' : '#8821f6', height : 'auto'}}>
                                  {productEvent[Key].event_name}
                                </h5>
                              </div>
                              <p className="card-text mb-2 size-12">
                                {productEvent[Key].kota+', '+productEvent[Key].province}
                              </p>
                              <h5 className="card-title text-danger mb-1 h-auto">
                                Rp {priceSplitter(productEvent[Key].start_price === null ? 0 : productEvent[Key].start_price)}
                              </h5>
                              <div className="mb-1 color-text size-10 mt-0 d-flex justify-content-start align-items-center">
                                <div className="w-12 mr-1">
                                  <img src="assets/star.png" alt="" className="mr-1"/>
                                </div>
                                Poin 
                                <span className="ml-1 text-primary size-10 d-inline">{productEvent[Key].poin_reward}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  }
                })
              }
            </OwlCarousel>
          </div>
        </section>
        <section className="em_swiper_products emCoureses__grid margin-b-10 bg-white py-3">
          <div className="em_title_swiper">
            <div className="txt">
              <h5 className="size-16 mb-1">Acara Olahraga Menarik</h5>
              <p>Beli tiket & ikuti keseruannya.</p>
            </div>
            <div className="item_link">
              <span className="color-blue cursor-pointer" onClick={() => {toProduct(3)}}>View all</span>
            </div>
          </div>
          <div className="em_bodyCarousel padding-t-10">
            <OwlCarousel 
              items={carouselItem} 
              autoWidth={true}
              margin={10}
              stagePadding={5} 
              className="owl-carousel owl-theme owlThemeCorses owl-loaded" 
              style={{minHeight : '50px'}}>
              {
                productSport.map((sport, Key) => {
                  // console.log(productSport[Key].event_name)
                  if(Key < 5){
                    return (
                      <div className="item" key={Key}>
                        <div className="em_itemCourse_grid">
                          <div onClick={() => toProductDetail(productSport[Key].slug_url)} className="card">
                            <div className="cover_card">
                              <img src={assetsSport+productSport[Key].id_event+'/'+productSport[Key].foto_banner} className="card-img-top" alt="img" />
                            </div>
                            <div className="card-body p-2">
                              <div className="head_card mb-1">
                                <span>
                                  {productSport[Key].start_date === productSport[Key].end_date ? productSport[Key].start_date : productSport[Key].start_date+' - '+productSport[Key].end_date}
                                </span>
                              </div>
                              <div className="head_card mb-1 h-40 d-flex align-items-start">
                                <h5 className="card-title" style={{'color' : '#8821f6', height : 'auto'}}>
                                  {productSport[Key].event_name}
                                </h5>
                              </div>
                              <p className="card-text mb-2 size-12">
                                {productSport[Key].kota+', '+productSport[Key].province}
                              </p>
                              <h5 className="card-title text-danger mb-1 h-auto">
                                Rp {priceSplitter(productSport[Key].start_price === null ? 0 : productSport[Key].start_price)}
                              </h5>
                              <div className="mb-1 color-text size-10 mt-0 d-flex justify-content-start align-items-center">
                                <div className="w-12 mr-1">
                                  <img src="assets/star.png" alt="" className="mr-1"/>
                                </div>
                                Poin 
                                <span className="ml-1 text-primary size-10 d-inline">{productSport[Key].poin_reward}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  }
                })
              }
            </OwlCarousel>
          </div>
        </section>
        <section className="em_swiper_products emCoureses__grid margin-b-10 bg-white py-3">
          <div className="em_title_swiper">
            <div className="txt">
              <h5 className="size-16 mb-1">Tempat Wisata Populer</h5>
              <p>Kunjungi tempat-tempat wisata terbaik.</p>
            </div>
            <div className="item_link">
              <a className="color-blue cursor-pointer" href='/product-wisata'>View all</a>
            </div>
          </div>
          <div className="em_bodyCarousel padding-t-10">
            <OwlCarousel 
              items={carouselItem}
              autoWidth={true}
              margin={10}
              stagePadding={5} 
              className="owl-carousel owl-theme owlThemeCorses owl-loaded" 
              style={{minHeight : '50px'}}>
              {
                productWahana.map((wahana, Key) => {
                  // console.log(productWahana[Key].gallery_photo.split(','))
                  const imageBanner = productWahana[Key].gallery_photo?.split(',');
                  if(Key < 5){
                    return (
                      <div className="item" key={Key}>
                        <div className="em_itemCourse_grid">
                          <div onClick={() => toProductWisataDetail(productWahana[Key].slug_url, productWahana[Key].business_type)} className="card">
                            <div className="cover_card">
                              <img src={assetsWisata+productWahana[Key].client+'/'+imageBanner[0]} className="card-img-top" alt="img" />
                            </div>
                            <div className="card-body p-2">
                              <div className="head_card mb-1">
                                <span>
                                  <svg className="w-16 h-16 dark:text-white mr-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                                  </svg>
                                  {productWahana[Key].jam_buka+' - '+productWahana[Key].jam_tutup}
                                </span>
                              </div>
                              <div className="head_card mb-1 h-40 d-flex align-items-start">
                                <h5 className="card-title" style={{'color' : '#8821f6', height : 'auto'}}>
                                  {productWahana[Key].ticket_name}
                                </h5>
                              </div>
                              <p className="card-text mb-2 size-12">
                                {productWahana[Key].city+', '+productWahana[Key].province}
                              </p>
                              <h5 className="card-title text-danger mb-1 h-auto">
                                Rp {priceSplitter(productWahana[Key].start_price === null ? 0 : productWahana[Key].start_price)}
                              </h5>
                              <div className="mb-1 color-text size-10 mt-0 d-flex justify-content-start align-items-center">
                                <div className="w-12 mr-1">
                                  <img src="assets/star.png" alt="" className="mr-1"/>
                                </div>
                                Poin 
                                <span className="ml-1 text-primary size-10 d-inline">{productWahana[Key].poin_reward}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  }
                })
              }
            </OwlCarousel>
          </div>
        </section>
        <section className="em_swiper_products emCoureses__grid bg-white py-3">
          <div className="em_title_swiper mb-4">
            <div className="w-100 h-100">
              <div className="em_side_right">
                <img src="assets/juara-tiket.png" width={'auto'} height={45} alt="" className=""/>
              </div>
            </div>
          </div>
          <div className="em_title_swiper footer-mainpage align-items-start mb-3">
            <div className="border-bottom w-100 d-flex ">
            <div className="w-50">
              <div className="emBK__transactions">
                <div className="item_trans p-1 mb-2">
                  <div className="media sideLeft">
                    <div className="icon_img border border-snow fill-primary mr-2">
                      <svg className="w-24 h-24 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24">
                        <path fill="currentColor" fillRule="evenodd" d="M12 4a8 8 0 0 0-6.895 12.06l.569.718-.697 2.359 2.32-.648.379.243A8 8 0 1 0 12 4ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a9.96 9.96 0 0 1-5.016-1.347l-4.948 1.382 1.426-4.829-.006-.007-.033-.055A9.958 9.958 0 0 1 2 12Z" clipRule="evenodd" />
                        <path fill="currentColor" d="M16.735 13.492c-.038-.018-1.497-.736-1.756-.83a1.008 1.008 0 0 0-.34-.075c-.196 0-.362.098-.49.291-.146.217-.587.732-.723.886-.018.02-.042.045-.057.045-.013 0-.239-.093-.307-.123-1.564-.68-2.751-2.313-2.914-2.589-.023-.04-.024-.057-.024-.057.005-.021.058-.074.085-.101.08-.079.166-.182.249-.283l.117-.14c.121-.14.175-.25.237-.375l.033-.066a.68.68 0 0 0-.02-.64c-.034-.069-.65-1.555-.715-1.711-.158-.377-.366-.552-.655-.552-.027 0 0 0-.112.005-.137.005-.883.104-1.213.311-.35.22-.94.924-.94 2.16 0 1.112.705 2.162 1.008 2.561l.041.06c1.161 1.695 2.608 2.951 4.074 3.537 1.412.564 2.081.63 2.461.63.16 0 .288-.013.4-.024l.072-.007c.488-.043 1.56-.599 1.804-1.276.192-.534.243-1.117.115-1.329-.088-.144-.239-.216-.43-.308Z" />
                      </svg>
                    </div>
                    <div className="media-body my-auto">
                      <h4 className="mb-0">WhatsApp</h4>
                      <a href="https://wa.me/6281317409518" target="_blank" className="text-decoration-none size-12 color-text">0897 5795 136</a>
                    </div>
                  </div>
                </div>
                <div className="item_trans p-1 mb-3">
                  <div className="media sideLeft">
                    <div className="icon_img border border-snow fill-primary mr-2">
                      <svg className="w-24 h-24 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="currentColor" viewBox="0 0 24 24">
                        <path d="M2.038 5.61A2.01 2.01 0 0 0 2 6v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6c0-.12-.01-.238-.03-.352l-.866.65-7.89 6.032a2 2 0 0 1-2.429 0L2.884 6.288l-.846-.677Z" />
                        <path d="M20.677 4.117A1.996 1.996 0 0 0 20 4H4c-.225 0-.44.037-.642.105l.758.607L12 10.742 19.9 4.7l.777-.583Z" />
                      </svg>
                    </div>
                    <div className="media-body my-auto">
                      <h4 className="mb-0">Email</h4>
                      <a href="mailto:juaratiketplatform@gmail.com" target="_blank" className="text-decoration-none size-12 color-text">cs@juaratiket.com</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-50">
              <div className="emBK__transactions">
                <div className="item_trans p-1 mb-2">
                  <div className="media sideLeft">
                    <div className="icon_img border border-snow fill-primary mr-2">
                      <svg className="w-24 h-24 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="currentColor" viewBox="0 0 24 24">
                        <path fillRule="evenodd" d="M4 3a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h1v2a1 1 0 0 0 1.707.707L9.414 13H15a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4Z" clipRule="evenodd" />
                        <path fillRule="evenodd" d="M8.023 17.215c.033-.03.066-.062.098-.094L10.243 15H15a3 3 0 0 0 3-3V8h2a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-1v2a1 1 0 0 1-1.707.707L14.586 18H9a1 1 0 0 1-.977-.785Z" clipRule="evenodd" />
                      </svg>
                    </div>
                    <div className="media-body my-auto">
                      <h4 className="mb-0">LiveChat</h4>
                      <a href="https://direct.lc.chat/17738955/" target="_blank" className="text-decoration-none size-12 color-text">Chat Now</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
          <div className="em_title_swiper footer-mainpage align-items-start mb-3">
            <div className="w-100 d-flex ">
              <div className="w-50">
                <h5 className="size-14 mb-3 d-block">Perusahaan</h5>
                <ul className="size-12" style={{listStyleType : 'none', paddingLeft : 0}}>
                  <li className="mb-2"><a href="#" className="text-decoration-none color-secondary">Tentang Juara Tiket</a></li>
                  <li className="mb-2"><a href="#" className="text-decoration-none color-secondary">Blog</a></li>
                  <li className="mb-2"><a href="#" className="text-decoration-none color-secondary">Karir</a></li>
                  <li className="mb-2"><a href="#" className="text-decoration-none color-secondary">Mitra</a></li>
                  <li className="mb-2"><a href="#" className="text-decoration-none color-secondary">Perlindungan Asuransi</a></li>
                </ul>
              </div>
              <div className="w-50">
                <h5 className="size-14 mb-3 d-block">Produk</h5>
                <ul className="size-12" style={{listStyleType : 'none', paddingLeft : 0}}>
                  <li className="mb-2"><a href="#" className="text-decoration-none color-secondary">Event Publik</a></li>
                  <li className="mb-2"><a href="#" className="text-decoration-none color-secondary">Event Corporate</a></li>
                  <li className="mb-2"><a href="#" className="text-decoration-none color-secondary">Tiket Wisata</a></li>
                  <li className="mb-2"><a href="#" className="text-decoration-none color-secondary">Event Olahraga</a></li>
                  <li className="mb-2"><a href="#" className="text-decoration-none color-secondary">Pendaftaran Peserta Olahraga</a></li>
                  <li className="mb-2"><a href="#" className="text-decoration-none color-secondary">Booklet Promo</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="em_title_swiper footer-mainpage align-items-start mb-3">
            <div className="border-bottom w-100 d-flex ">
              <div className="w-50 pb-3">
                <h5 className="size-14 mb-3 d-block">Download Aplikasi</h5>
                <div className="em_side_right mb-2">
                  <img src="assets/img/appstore.png" width={'auto'} height={35} alt="" className=""/>
                </div>
                <div className="em_side_right">
                  <img src="assets/img/playstore.png" width={'auto'} height={35} alt="" className=""/>
                </div>
              </div>
            </div>
          </div>
          <div className="em_title_swiper footer-mainpage align-items-start mb-3">
            <div className="w-100 d-flex ">
              <div className="w-50">
                <h5 className="size-14 mb-3 d-block">Official Partner</h5>
                <div className="em_side_right">
                  <img src="https://mitra.juaratiket.com/assets/img/dispar%20banten.png" width={'auto'} height={30} alt="" className=""/>
                </div>
              </div>
            </div>
          </div>
          <div className="em_title_swiper footer-mainpage align-items-start mb-3">
            <div className="w-100 d-flex ">
              <div className="w-100">
                <h5 className="size-14 mb-3 d-block">Keamanan Transaksi</h5>
                <div className="d-flex ">
                  <div className="em_side_right">
                    <img src="https://www.xendit.co/wp-content/uploads/2022/03/TR-Testmark_0000065832_EN_CMYK_without-QR-Code_hi-res-01.png" width={'auto'} height={45} alt="" className=""/>
                  </div>
                  <div className="em_side_right">
                    <img src="https://www.xendit.co/wp-content/uploads/2022/04/Xendit-Certificate-Bank-Indonesia-hires-min-768x296.png" width={'auto'} height={45} alt="" className=""/>
                  </div>
                  <div className="em_side_right">
                    <img src="assets/img/kominfo.png" width={'auto'} height={45} alt="" className=""/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="em_title_swiper footer-mainpage align-items-start mb-3">
            <div className="border-bottom w-100 d-flex ">
              <div className="w-100 pb-3">
                <h5 className="size-14 mb-3 d-block">Ikuti Kami</h5>
                <div className="itemNetworks p-0 justify-content-start">
                  <a href="#" className="btn facebook">
                    <img src="assets/img/icon/facebook.svg" alt="" />
                  </a>
                  <a href="#" className="btn instagram">
                    <img src="assets/img/icon/instagram.svg" alt="" />
                  </a>
                  <a href="#" className="btn youtube">
                    <img src="assets/img/icon/youtube.svg" alt="" />
                  </a>
                  <a href="#" className="btn twitter">
                    <i className="ri-twitter-fill color-twitter" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="em_title_swiper footer-mainpage align-items-start mb-2">
            <div className="w-100 d-flex justify-content-between">
              <div className="color-text size-12 w-75 pr-4">
                <p>Copyright © 2024 PT. Juara Digital Platform. All Rights Reserved.</p>
              </div>
              <div className="">
                <img src="assets/juara-tiket.png" width={'auto'} height={30} alt="" className=""/>
              </div>
            </div>
          </div>
        </section>
      </div>
    // ) : (
    //   <section className="em_loading" id="loader" style={{display: loaderDisplay}}>
    //     <div className="spinner-grow bg-primary" role="status">
    //       <span className="sr-only">Loading...</span>
    //     </div>
    //   </section>
    // )
  )
}
export default Main