import React, {useState, useEffect, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import {login} from '../Api/Auth';
import ButtonGoole from '../Components/ButtonGoogleSignIn'
import { GoogleOAuthProvider } from '@react-oauth/google'
const GoogleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID

function AuthLogin(){
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validation, setValidation] = useState("")
  
  const [loaderDisplayBg0, setLoaderDisplayBg0] = useState("none")
  const [loaderDisplay, setLoaderDisplay] = useState("flex")
  const inputRef = useRef(null)

  const checkUserToken = () => {
    if (localStorage.getItem('token')) {
      return navigate('/home')
    }else{
      setTimeout(function() { 
        setLoaderDisplay('none')
      }, 1000)
      inputRef.current.focus()
    }
  }

  const loginHandler = async (e) => {
    setValidation("")
    setLoaderDisplayBg0('flex')
    e.preventDefault()
    setTimeout(function() { 
      const formData = new FormData();
      formData.append('email', email);
      formData.append('current_password', password);
      login(formData).then((res) => {
        if(res.responseCode === 200000){
          window.location.replace('/home')
        }else{
          setValidation(res.responseMessage)
          inputRef.current.focus()
        }
        setLoaderDisplayBg0('none')
      })
    }, 1000)
  }

  useEffect(function() {
    checkUserToken()
  },[])

  return (
    <div id="wrapper" className="background-img" style={{'height' : '100vh'}}>
      <section className="em_loading" id="loader" style={{display: loaderDisplay}}>
        <div className="spinner-grow bg-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </section>
      <section className="em_loading" id="loaderBg0" style={{display: loaderDisplayBg0, backgroundColor: 'rgb(41 46 52 / 0%)'}}>
        <div className="spinner-grow bg-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </section>
      <div>
        {/* Start main_haeder */}
        <header className="main_haeder header-sticky multi_item">
          <div className="em_side_right">
            {/* <span className="page_name h5 text-logo-gradient d-inline">
              JUARA TIKET
            </span> */}
            <img src="assets/juara-tiket.png" width={'auto'} height={50} alt="" className="mt-2"/>
          </div>
          <div className="em_side_right">
            <a href="/home" className="link__forgot d-block size-14 color-text text-decoration-none hover:color-secondary transition-all">
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M18 6l-12 12" /><path d="M6 6l12 12" /></svg>
            </a>
          </div>
        </header>
        {/* End.main_haeder */}
        {/* Start em__signTypeOne */}
        <section className="em__signTypeOne typeTwo np__account padding-t-70">
          <div className="em_titleSign">
            <h1>Welcome Back!</h1>
            <p>Enter your credentials to continue</p>
            <div className="logSocial">
              <GoogleOAuthProvider clientId={GoogleClientId}>
                <ButtonGoole/>
              </GoogleOAuthProvider>
            </div>
          </div>
          <div className="em__body">
            <form onSubmit={loginHandler} className="padding-t-10">
              <div className="margin-b-30">
                <div className="form-group input-lined lined__iconed">
                  <div className="input_group">
                    <input type="email" name="email" className="form-control" id="email" placeholder="example@mail.com" ref={inputRef} value={email} onChange={(e) => setEmail(e.target.value)} required />
                    <div className="icon">
                      <svg id="Iconly_Curved_Message" data-name="Iconly/Curved/Message" xmlns="http://www.w3.org/2000/svg" width={22} height={22} viewBox="0 0 22 22">
                        <g id="Message" transform="translate(2.248 2.614)">
                          <path id="Stroke_1" data-name="Stroke 1" d="M10.222,0S7.279,3.532,5.127,3.532,0,0,0,0" transform="translate(3.613 5.653)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                          <path id="Stroke_3" data-name="Stroke 3" d="M0,8.357C0,2.089,2.183,0,8.73,0s8.73,2.089,8.73,8.357-2.183,8.357-8.73,8.357S0,14.624,0,8.357Z" transform="translate(0 0)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                        </g>
                      </svg>
                    </div>
                  </div>
                  <label htmlFor="email">Email Address</label>
                </div>
              </div>
              <div className="form-group input-lined lined__iconed show_hide_password">
                <div className="input_group">
                  <input type="password" id="password" name="password" className="form-control" placeholder="enter your password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                  <div className="icon">
                    <svg id="Iconly_Curved_Password" data-name="Iconly/Curved/Password" xmlns="http://www.w3.org/2000/svg" width={22} height={22} viewBox="0 0 22 22">
                      <g id="Password" transform="translate(2.521 2.521)">
                        <path id="Stroke_1" data-name="Stroke 1" d="M3.4,1.7A1.7,1.7,0,1,1,1.7,0h0A1.7,1.7,0,0,1,3.4,1.7Z" transform="translate(3.882 6.781)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                        <path id="Stroke_3" data-name="Stroke 3" d="M0,0H5.792V1.7" transform="translate(7.28 8.479)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                        <path id="Stroke_5" data-name="Stroke 5" d="M.5,1.7V0" transform="translate(9.979 8.479)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                        <path id="Stroke_7" data-name="Stroke 7" d="M0,8.479C0,2.12,2.12,0,8.479,0s8.479,2.12,8.479,8.479-2.12,8.479-8.479,8.479S0,14.838,0,8.479Z" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                      </g>
                    </svg>
                  </div>
                  <button type="button" className="btn hide_show icon_password">
                    <i className="tio-hidden_outlined" />
                  </button>
                </div>
                <label htmlFor="pass">Password</label>
              </div>
              <a href="/forgot-password" className="link__forgot d-block size-14 color-text text-decoration-none hover:color-primary transition-all">
                Forgot Password?
              </a>
              <div className="alert color-comment text-center" role="alert">
                {
                  validation !== "" ? (<div>{validation}</div>) : ''
                }
              </div>
              <div className="buttons__footer text-center bg-transparent">
                <button type="submit" className="btn bg-white rounded-pill btn__default">
                  <span className="color-primary">Sign in</span>
                  <div className="icon rounded-circle bg-primary">
                    <i className="tio-chevron_right" />
                  </div>
                </button>
                <a href="/register" className="btn bg-white rounded-pill btn__default">
                  <span className="color-secondary size-14">
                    I don't have an account
                  </span>
                </a>
              </div>
            </form>
          </div>
        </section>
        {/* End. em__signTypeOne */}
      </div>
    </div>
  )
}

export default AuthLogin;